import { SxProps, Theme } from "@mui/material";

const modalBoxStyle: SxProps<Theme> = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "400px",
    maxWidth: "90%",
    maxHeight: "90vh",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 3,
    outline: "none",
    overflowY: "auto",
};

const agentList: SxProps = {
    maxHeight: "300px",
    overflowY: "auto",
    "&::-webkit-scrollbar": {
        width: "8px",
    },
    "&::-webkit-scrollbar-track": {
        background: "#f1f1f1",
        borderRadius: "4px",
    },
    "&::-webkit-scrollbar-thumb": {
        background: "#888",
        borderRadius: "4px",
        "&:hover": {
            background: "#555",
        },
    },
};

export { agentList, modalBoxStyle };
