import { useEffect } from "react";

const Tutorial = () => {
    useEffect(() => {
        window.location.href =
            "https://firebasestorage.googleapis.com/v0/b/muggle-ai-action-script-uswest1/o/public%2FHow%20to%20use%20Muggle%20AI.pdf?alt=media&token=91321a23-21ac-4b69-95e5-e027673cfa2e";
    }, []);

    return null;
};

export default Tutorial;
