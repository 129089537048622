import { useAuth0 } from "@auth0/auth0-react";
import { Box, Button, Checkbox, FormControlLabel, Paper, Typography } from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { REACT_APP_BACKEND_PROTECTED_URL } from "../../configs/config";

const Agreement: React.FC = () => {
    const [agreed, setAgreed] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const navigate = useNavigate();
    const { user, getAccessTokenSilently } = useAuth0();

    const handleAgree = async () => {
        if (!user?.sub) {
            console.error("No user ID available");
            return;
        }

        setIsSubmitting(true);

        try {
            const token = await getAccessTokenSilently();
            const agreementData = {
                agreed: true,
                timestamp: new Date().toISOString(),
                version: "1.0",
                userId: user!.sub,
                userEmail: user!.email,
                userAgent: navigator.userAgent,
                consentType: "Twilio communications opt-in",
                consentDetails: {
                    communicationsConsent: true,
                    termsAccepted: true,
                    privacyPolicyAccepted: true,
                },
            };

            // Store in backend
            const response = await fetch(`${REACT_APP_BACKEND_PROTECTED_URL}/optIn/${user!.sub}`, {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(agreementData),
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            // Store in localStorage for immediate access
            console.log("Agreement data:", response.body);
            localStorage.setItem("userAgreement", JSON.stringify(agreementData));
            navigate("/");
        } catch (error) {
            console.error("Failed to save agreement:", error);
            // You might want to show an error message to the user here
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <Paper elevation={3} sx={{ borderRadius: 2, backgroundColor: "#3c4245" }}>
            <Box sx={{ p: 4, maxWidth: 800, mx: "auto" }}>
                <Typography variant="h4" gutterBottom>
                    Terms of Service, Privacy Policy & Communications Consent
                </Typography>

                <Typography variant="body1" paragraph>
                    Welcome to our service. Before proceeding, please carefully review and agree to our terms.
                </Typography>

                <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
                    Communications Consent
                </Typography>
                <Typography paragraph>
                    By checking the box below, you expressly consent to receive communications from us, including
                    through the use of automated technology, artificial intelligence, or autodialed calls and text
                    messages at the phone number(s) you provide. You understand that:
                </Typography>

                <Box component="ul" sx={{ mb: 2 }}>
                    <Typography component="li">
                        Your consent is not required to purchase any goods or services
                    </Typography>
                    <Typography component="li">Message and data rates may apply</Typography>
                    <Typography component="li">You may receive periodic recurring messages</Typography>
                    <Typography component="li">
                        You can opt-out at any time by texting STOP or through your account settings
                    </Typography>
                    <Typography component="li">Messaging frequency may vary</Typography>
                </Box>

                <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
                    Terms of Service & Privacy Policy
                </Typography>
                <Typography paragraph>
                    By using our service, you agree to our Terms of Service and acknowledge our Privacy Policy, which
                    explain how we collect, use, and protect your personal information. You can review these documents
                    in full at any time through our website.
                </Typography>

                <Box sx={{ mt: 3, mb: 3 }}>
                    <FormControlLabel
                        control={<Checkbox checked={agreed} onChange={(e) => setAgreed(e.target.checked)} />}
                        label={
                            <Typography variant="body2">
                                I agree to the Terms of Service, Privacy Policy, and consent to receive communications
                                as described above, including automated calls and text messages. I understand that my
                                consent is not required to make a purchase.
                            </Typography>
                        }
                    />
                </Box>

                <Box sx={{ mt: 3 }}>
                    <Button variant="contained" disabled={!agreed || isSubmitting} onClick={handleAgree} size="large">
                        {isSubmitting ? "Saving..." : "I Agree & Continue"}
                    </Button>
                </Box>
            </Box>
        </Paper>
    );
};

export default Agreement;
