import { REACT_APP_BACKEND_PROTECTED_URL } from "../../configs/config";
import { ISolution } from "../../models/interfaces/solution/ISolution";
import { ISolutionPromptView } from "../../models/interfaces/solution/ISolutionPromptView";
import GetRequestHeader from "../../utils/auth_utils";

const GetSolutionUrl = (solutionId: string) => `${REACT_APP_BACKEND_PROTECTED_URL}/solution/${solutionId}`;

const ListSolutionVersionsUrl = (solutionId: string) => {
    return `${REACT_APP_BACKEND_PROTECTED_URL}/solution/${solutionId}/version`;
};

const ResetSolutionVersionUrl = (solutionId: string, versionId: number) =>
    `${REACT_APP_BACKEND_PROTECTED_URL}/solution/${solutionId}/version/${versionId}`;

const GetSolutionOptions = (authToken?: string) => GetRequestHeader({ method: "GET", authToken: authToken });
const DeleteSolutionOptions = (authToken?: string) => GetRequestHeader({ method: "DELETE", authToken: authToken });

const ResetSolutionVersionOptions = (body: any, authToken?: string) =>
    GetRequestHeader({
        method: "PUT",
        body: JSON.stringify(body),
        authToken: authToken,
    });

async function GetSolutionById(solutionId: string, authToken?: string): Promise<ISolution> {
    const response = await fetch(GetSolutionUrl(solutionId), GetSolutionOptions(authToken));
    if (!response.ok) {
        throw new Error(response.statusText);
    }
    const data = await response.json();
    const solution: ISolution = {
        solutionId: data.solutionId,
        solutionName: data.solutionName,
        solutionDescription: data.solutionDescription,
        agents: data.agents,
        solutionSystemPrompt: data.solutionSystemPrompt,
        ownerId: data.ownerId,
        isActive: data.isActive,
        isPrivate: data.isPrivate,
        properties: data.properties,
        tags: data.tags,
        versionId: data.versionId,
        creationTimeUtc: data.creationTimeUtc,
        creationTimeUtcReadable: data.creationTimeUtcReadable,
        firstMsg: data.firstMsg,
    };
    return solution;
}

async function DeleteSolutionById(solutionId: string, authToken?: string): Promise<ISolution> {
    return fetch(GetSolutionUrl(solutionId), DeleteSolutionOptions(authToken))
        .then((response) => {
            if (!response.ok) {
                throw new Error(response.statusText);
            }
            return response.json() as Promise<ISolution>;
        })
        .then((data) => {
            return data;
        });
}

const resetSolutionVersion = async ({
    solutionId,
    versionId,
    authToken,
}: {
    solutionId: string;
    versionId: number;
    authToken?: string;
}): Promise<ISolutionPromptView> => {
    const resp = await fetch(
        ResetSolutionVersionUrl(solutionId, versionId),
        ResetSolutionVersionOptions({ solutionId, versionId }, authToken)
    );
    if (!resp.ok) throw new Error("API request failed: " + resp.text);
    const data = await resp.json();
    const solutionPromptObj: ISolutionPromptView = {
        ...data,
    };
    return solutionPromptObj;
};

export {
    DeleteSolutionById,
    GetSolutionById,
    GetSolutionOptions,
    GetSolutionUrl,
    ListSolutionVersionsUrl,
    resetSolutionVersion,
};
