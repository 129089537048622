import { useAuth0 } from "@auth0/auth0-react";
import { Box, Button } from "@mui/material";
import { buttonStyle } from "../../../styles/navBarStyle";
import ConnectionStatus from "./ConnectionStatus";

function Profile() {
    const { isAuthenticated, logout, user } = useAuth0();
    return isAuthenticated ? (
        <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
            <Box sx={{ display: "flex", alignItems: "center", fontFamily: "Montserrat", fontSize: "1.1rem" }}>
                <ConnectionStatus />
                Howdy, <b>{user?.name}</b>
            </Box>
            <Button
                sx={{
                    ...buttonStyle,
                    marginRight: 0, // Override the default margin since it's the last item
                }}
                onClick={() => {
                    logout({
                        logoutParams: {
                            returnTo: window.location.origin,
                        },
                    });
                }}
            >
                Log out
            </Button>
        </Box>
    ) : null;
}

export default Profile;
