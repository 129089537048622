import { SxProps, Theme } from "@mui/material";

export const chatMessageBoxStyle: SxProps<Theme> = {
    bgcolor: "#2C2C2C",
    color: "#E0E0E0",
    height: "calc(100vh - 300px)",
    overflowY: "auto",
    overflowX: "hidden",
    p: 2,
    borderRadius: 1,
    border: "1px solid #404040",
    display: "flex",
    flexDirection: "column",
    "&::-webkit-scrollbar": {
        width: "8px",
        height: "8px",
    },
    "&::-webkit-scrollbar-track": {
        bgcolor: "#1E1E1E",
    },
    "&::-webkit-scrollbar-thumb": {
        bgcolor: "#404040",
        borderRadius: "4px",
        "&:hover": {
            bgcolor: "#666666",
        },
    },
};

export const chatMessageStyle: SxProps<Theme> = {
    mb: 1,
    p: 0.5,
    transition: "all 0.2s ease-in-out",
    width: "100%",
};

export const chatMessageBodyStyle: SxProps<Theme> = {
    mt: 0.5,
    color: "#E0E0E0",
    lineHeight: 1.2,
    whiteSpace: "pre-wrap",
    wordBreak: "break-word",
};

export const actionMessageStyle: SxProps<Theme> = {
    ...chatMessageStyle,
    color: "#B0B0B0",
    fontStyle: "italic",
};
