import { createTheme, type Theme } from "@mui/material";
import { blue, grey } from "@mui/material/colors";

export const customColors = {
    red: "#f44336",
    green: "#4caf50",
    blue: "#2196f3",
    yellow: "#ffeb3b",
    brown: "#FFC1C1",
};

const theme: Theme = createTheme({
    typography: {
        fontFamily: "Open Sans",
        h1: {
            color: "#fff",
        },
        h2: {
            color: "#fff",
        },
        h3: {
            color: "#fff",
        },
        h4: {
            color: "#fff",
        },
    },
    palette: {
        primary: {
            main: grey[900],
        },
        secondary: {
            main: blue[500],
        },

        background: {
            default: "#3C3B3B",
        },
    },
});

// Create a dark theme
const darkTheme: Theme = createTheme({
    palette: {
        mode: "dark",
    },
});

export { darkTheme, theme };
