import { Box, Tooltip } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { SocketContext } from "../../../context/SocketContext";

const ConnectionStatus = () => {
    const { socket, loading } = useContext(SocketContext);
    const [isConnected, setIsConnected] = useState(false);

    useEffect(() => {
        if (!socket) return;

        const updateConnectionStatus = () => setIsConnected(socket.connected);

        socket.on("connect", updateConnectionStatus);
        socket.on("disconnect", updateConnectionStatus);

        // Set initial state
        setIsConnected(socket.connected);

        return () => {
            socket.off("connect", updateConnectionStatus);
            socket.off("disconnect", updateConnectionStatus);
        };
    }, [socket]);

    const getStatusColor = () => {
        if (loading) return "#FFA500"; // Orange for loading
        return isConnected ? "#4CAF50" : "#F44336"; // Green for connected, Red for disconnected
    };

    const getStatusText = () => {
        if (loading) return "Connecting to server...";
        return isConnected ? "Connected to server" : "Disconnected from server";
    };

    return (
        <Tooltip title={getStatusText()} arrow placement="bottom">
            <Box
                sx={{
                    width: 10,
                    height: 10,
                    borderRadius: "50%",
                    backgroundColor: getStatusColor(),
                    marginRight: 1,
                    transition: "background-color 0.3s ease",
                    cursor: "help",
                }}
            />
        </Tooltip>
    );
};

export default ConnectionStatus;
