import ReactDOM from "react-dom/client";
import App from "./App";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
// MUI font
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

import React from "react";
import { BrowserRouter } from "react-router-dom";
import Auth0ProviderWithRedirectCallback from "./components/auth/Auth0ProviderWithRedirectCallback";
import GoogleAnalyticsHeader from "./components/auth/google_analytics_header";
import * as config from "./configs/config";
import SocketProvider from "./context/SocketContextProvider";
import { initializeAppInsights, logMetric } from "./utils/appInsights";

// Initialize Application Insights
initializeAppInsights();

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

root.render(
    <React.StrictMode>
        <GoogleAnalyticsHeader />
        <BrowserRouter>
            <Auth0ProviderWithRedirectCallback
                props={{
                    domain: config.REACT_APP_AUTH0_DOMAIN,
                    clientId: config.REACT_APP_AUTH0_CLIENT_ID,
                    authorizationParams: {
                        redirect_uri: window.location.origin,
                        audience: config.REACT_APP_AUTH0_AUDIENCE,
                        scope: "openid profile email offline_access",
                    },
                    cacheLocation: "localstorage",
                }}
            >
                <SocketProvider>
                    <App />
                </SocketProvider>
            </Auth0ProviderWithRedirectCallback>
        </BrowserRouter>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals((metric) => {
    logMetric(`WebVital_${metric.name}`, metric.value, {
        id: metric.id,
        name: metric.name,
        value: metric.value,
        delta: metric.delta,
    });
});
