import { Box, Container, Grid, Link } from "@mui/material";
import React from "react";

import banner from "../../assets/logo.png";

import { type SxProps, type Theme } from "@mui/system";
import SearchBox from "../../components/home/SearchBox";
export const buttonStyle: SxProps<Theme> = {
    variant: "contained",
};

const HomePage: React.FC = () => {
    // const url = `${REACT_APP_BACKEND_BASE_URL}/recommendation/popularPrompt`;
    // const options = useMemo(
    //   () => ({
    //     method: "GET",
    //     headers: {
    //       "Content-Type": "application/json",
    //     },
    //   }),
    //   []
    // );

    // const {
    //   data,
    //   loading,
    //   error,
    // }: { data: IPrompt[]; loading: boolean; error: any } = useFetch(url, options);

    return (
        <Container maxWidth="xl">
            <Grid container marginY={4}>
                <Grid item xs={12} sm={16} container justifyContent="center" alignItems="center">
                    <Box marginTop={2} display="flex" justifyContent="center" width="100%">
                        <Link href="/">
                            <img src={banner} alt="Logo" height={200} />
                        </Link>
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <SearchBox />
                </Grid>
                {/* <Grid item xs={12}>
          <PromptRecommendBox cards={data} loading={loading} error={error} />
        </Grid> */}
            </Grid>
        </Container>
    );
};

export default HomePage;
