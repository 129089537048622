import { Box, Button } from "@mui/material";
import React from "react";

import { LoadEntityTypeEnum } from "../../../../types/entity/LoadEntityEnum";

interface LoadButtonGroupProps {
    props: {
        handleDeleteRequest: () => void;
        handleLoadRequest: () => void;
        setIsLoadModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
        entityType: LoadEntityTypeEnum;
    };
}

const LoadButtonGroup: React.FC<LoadButtonGroupProps> = ({ props }) => {
    return (
        <Box mt={2} display="flex" justifyContent="flex-end">
            <Box padding={2}>
                <Button variant="contained" color="error" onClick={props.handleDeleteRequest}>
                    Delete This
                </Button>
            </Box>
            <Box padding={2}>
                <Button variant="contained" onClick={() => props.setIsLoadModalOpen(false)}>
                    Back
                </Button>
            </Box>
            <Box padding={2}>
                <Button variant="contained" color="secondary" onClick={props.handleLoadRequest}>
                    {`Load ${props.entityType}`}
                </Button>
            </Box>
        </Box>
    );
};

export default LoadButtonGroup;
