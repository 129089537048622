import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Button, Typography } from "@mui/material";
import React from "react";
import { IAgent } from "../../../../models/interfaces/workbench/agent/IAgent";
import { agentStatusStyle } from "../ChatboxStyle";

interface SelectAgentListProps {
    selectedAgents: { [key: string]: IAgent };
    setIsSelectAgentModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const SelectAgentList: React.FC<SelectAgentListProps> = ({ selectedAgents, setIsSelectAgentModalOpen }) => {
    return (
        <>
            <Typography sx={{ fontSize: "1.1rem", fontWeight: 500, color: "#E0E0E0", mb: 2 }}>
                Selected Agents
            </Typography>
            <Button
                variant="contained"
                color="secondary"
                fullWidth
                sx={{ mb: 2 }}
                onClick={() => {
                    setIsSelectAgentModalOpen(true);
                }}
            >
                Invite Agent
            </Button>
            {selectedAgents === undefined || selectedAgents == null || Object.keys(selectedAgents).length === 0 ? (
                <Typography sx={{ color: "#B0B0B0", mb: 1 }}>No Selected Agents</Typography>
            ) : (
                Object.keys(selectedAgents).map((agentId) => (
                    <Typography key={agentId} sx={agentStatusStyle}>
                        <CheckCircleIcon
                            fontSize="small"
                            sx={{
                                verticalAlign: "middle",
                                fontSize: "small",
                                mr: 1,
                                color: "#1976d2",
                            }}
                        />
                        {selectedAgents[agentId].agentName}
                    </Typography>
                ))
            )}
        </>
    );
};

export default SelectAgentList;
