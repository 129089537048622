import { Box, FormControlLabel, Grid, Paper, Switch } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import * as ConversationEvent from "../../../models/interfaces/events/conversation_events";
import { IAgentInstance } from "../../../models/interfaces/workbench/agent/IAgentInstance";
import { IChatParticipant } from "../../../models/interfaces/workbench/chat/IChatParticipant";
import { type IMessage } from "../../../models/interfaces/workbench/chat/IMessage";
import { ParticipantTypeEnum } from "../../../models/interfaces/workbench/chat/ParticipantTypeEnum";
import { type IChatBoxProp } from "../../../models/interfaces/workbench/chatbox/IChatBoxProp";
import { GetActiveAgents } from "../../agent/ManageAgent";
import SelectAgentModal from "../../workbench/select_agent_modal/SelectAgentModal";
import ActiveAgentList from "./agent_list_box/ActiveAgentList";
import SelectAgentList from "./agent_list_box/SelectAgentList";
import { agentStatusBoxStyle, chatAreaStyle, toggleContainerStyle, toggleLabelStyle } from "./ChatboxStyle";
import ChatInput from "./ChatInput";
import { ChatMessages } from "./ChatMessageWindow";

const Chatbox: React.FC<IChatBoxProp> = ({
    socket,
    userId,
    userName,
    conversation,
    setConversation,
    systemPrompt,
    isInitializing,
    setIsInitializing,
    startNewConversationAsync,
    selectedAgents,
    setSelectedAgents,
    activeAgentInstances,
    setActiveAgentInstances,
    messagesEndRef,
}) => {
    const [isSelectAgentModalOpen, setIsSelectAgentModalOpen] = useState(false);
    const [showAgentActionDetails, setShowAgentActionDetails] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    // Remove local messagesEndRef since we're using the one from props
    const handleToggleAgentActionDetails = () => {
        setShowAgentActionDetails((prev) => !prev);
    };

    // Keep track of chat participants profiles
    const [chatParticipantProfiles, setChatParticipantProfiles] = useState<Map<string, IChatParticipant>>(
        new Map<string, IChatParticipant>([
            [
                userId,
                {
                    participantId: userId,
                    participantType: ParticipantTypeEnum.User,
                    participantName: "You",
                },
            ],
        ])
    );

    // Fetch active agents routinely
    useEffect(() => {
        const tick = setInterval(() => {
            try {
                GetActiveAgents({ socket: socket, conversation: conversation })
                    .catch((err) => {
                        throw err;
                    })
                    .then((data) => {});
            } catch (error) {
                console.error("An error occurred:", error);
            }
        }, 20000);
        return () => {
            clearInterval(tick);
        };
    });

    const convertParticipantsProfiles = useCallback(
        (agentInstanceList: IAgentInstance[]) => {
            const newMap = new Map<string, IChatParticipant>([
                // Keep user profile in the map
                [
                    userId,
                    {
                        participantId: userId,
                        participantType: ParticipantTypeEnum.User,
                        participantName: "You",
                    },
                ],
            ]);

            agentInstanceList.forEach((agentInst: IAgentInstance) => {
                if (agentInst.agentId !== undefined) {
                    newMap.set(agentInst.agentInstanceId, {
                        participantId: agentInst.agentInstanceId,
                        participantType: ParticipantTypeEnum.Agent,
                        participantName: agentInst.agentName,
                    });
                }
            });
            return newMap;
        },
        [userId]
    );

    // Keep chat participant list updated if activeAgents changes
    useEffect(() => {
        setChatParticipantProfiles(convertParticipantsProfiles(activeAgentInstances));
    }, [activeAgentInstances, userId, conversation.chatSessionId, convertParticipantsProfiles]);

    // Update chat history when message comes
    useEffect(() => {
        if (socket === undefined) return;
        const messageListener = ({ newMsg }: { newMsg: IMessage }): void => {
            if (!newMsg?.messageId) return; // Skip messages without ID
            console.log(`New message: ${JSON.stringify(newMsg)}`);
            setConversation((prev) => {
                // Check if message already exists in history
                if (prev.messageHistory?.some((msg) => msg.messageId === newMsg.messageId)) {
                    return prev; // Return unchanged state if duplicate
                }
                // Create new state with spread operator instead of push
                return {
                    ...prev,
                    messageHistory: [...prev.messageHistory, newMsg],
                };
            });
        };

        const agInsUpdateListener = (data: IAgentInstance[]) => {
            console.debug(`Agent instances updated: ${JSON.stringify(data)}.`);
            setActiveAgentInstances(data);
        };

        socket.on(ConversationEvent.ON_MSG, messageListener);
        socket.on(ConversationEvent.UPDATE_AG_IN_CHAT, agInsUpdateListener);

        return () => {
            socket.off(ConversationEvent.ON_MSG);
            socket.off(ConversationEvent.UPDATE_AG_IN_CHAT);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [socket, conversation.chatSessionId]); // Add chatSessionId to dependencies

    return (
        <Grid container spacing={1}>
            <SelectAgentModal
                props={{
                    userId: userId,
                    isSelectAgentModalOpen: isSelectAgentModalOpen,
                    setIsSelectAgentModalOpen: setIsSelectAgentModalOpen,
                    setConversation: setConversation,
                    selectedAgents: selectedAgents,
                    setSelectedAgents: setSelectedAgents,
                    socket: socket,
                    chatSessionId: conversation.chatSessionId,
                    startNewConversationAsync: startNewConversationAsync,
                    systemPrompt: systemPrompt,
                }}
            />
            <Grid container item xs={12} spacing={1}>
                <Grid item xs={10}>
                    <Box sx={chatAreaStyle}>
                        <ChatMessages
                            conversation={conversation}
                            chatParticipantProfiles={chatParticipantProfiles}
                            endOfChatRef={messagesEndRef}
                            showAgentActionDetails={showAgentActionDetails}
                            isInitializing={isInitializing}
                            isLoading={isLoading}
                        />
                        <Box sx={{ mt: 1 }}>
                            <ChatInput
                                userId={userId}
                                userName={userName}
                                chatSessionId={conversation.chatSessionId}
                                socket={socket}
                                chatParticipantProfiles={chatParticipantProfiles}
                                setIsLoading={setIsLoading}
                            />
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={2} sx={{ display: "flex", flexDirection: "column" }}>
                    <Paper elevation={2} sx={toggleContainerStyle}>
                        <FormControlLabel
                            sx={toggleLabelStyle}
                            control={
                                <Switch
                                    checked={showAgentActionDetails}
                                    onChange={handleToggleAgentActionDetails}
                                    color="info"
                                />
                            }
                            label="Show Agent Action Details"
                        />
                    </Paper>
                    <Paper elevation={2} sx={agentStatusBoxStyle}>
                        {activeAgentInstances.length > 0 ? (
                            <ActiveAgentList
                                socket={socket}
                                chatSessionId={conversation.chatSessionId}
                                activeAgents={activeAgentInstances}
                                setIsSelectAgentModalOpen={setIsSelectAgentModalOpen}
                                selectedAgents={selectedAgents}
                                startNewConversationAsync={startNewConversationAsync}
                                systemPrompt={systemPrompt}
                                setIsInitializing={setIsInitializing}
                            />
                        ) : (
                            <SelectAgentList
                                selectedAgents={selectedAgents}
                                setIsSelectAgentModalOpen={setIsSelectAgentModalOpen}
                            />
                        )}
                    </Paper>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default Chatbox;
