// A react component to display a list of properties, and allow the user to add new key value pair or edit existing ones.

import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import { Box, IconButton, TextField, Typography } from "@mui/material";
import React, { useState } from "react";

interface PropertiesEditBoxProps {
    properties: { [key: string]: string };
    propertiesChange: (properties: { [key: string]: string }) => void;
}

const PropertiesEditBox: React.FC<PropertiesEditBoxProps> = ({ properties, propertiesChange }) => {
    const [newProperty, setNewProperty] = useState<{
        key: string;
        value: string;
    }>({ key: "", value: "" });

    const handleAddNewProperty = () => {
        if (newProperty.key.trim() !== "") {
            const newProperties = { ...properties };
            newProperties[newProperty.key] = newProperty.value;
            propertiesChange(newProperties);
            setNewProperty({ key: "", value: "" });
        }
    };

    const handlePropertyChange = (key: string, value: string) => {
        const newProperties = { ...properties };
        newProperties[key] = value;
        propertiesChange(newProperties);
    };

    const handleDeleteProperty = (key: string) => {
        const newProperties = { ...properties };
        delete newProperties[key];
        propertiesChange(newProperties);
    };

    const handleKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (event.key === "Enter") {
            handleAddNewProperty();
        }
    };

    return (
        <Box display="flex" flexDirection="column">
            <Typography variant="subtitle2" sx={{ mb: 1 }}>
                Properties
            </Typography>
            {Object.entries(properties).map(([key, value]) => (
                <Box key={key} display="flex" justifyContent="space-between" alignItems="center" sx={{ mb: 1 }}>
                    <TextField
                        label="Key"
                        variant="filled"
                        value={key}
                        onChange={(e) => handlePropertyChange(key, e.target.value)}
                        size="small"
                        sx={{ mr: 1, flex: 1 }}
                    />
                    <TextField
                        label="Value"
                        variant="filled"
                        value={value}
                        onChange={(e) => handlePropertyChange(key, e.target.value)}
                        size="small"
                        sx={{ mr: 1, flex: 1 }}
                    />
                    <IconButton onClick={() => handleDeleteProperty(key)} size="small">
                        <DeleteIcon />
                    </IconButton>
                </Box>
            ))}
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <TextField
                    label="Add New Key"
                    variant="filled"
                    value={newProperty.key}
                    onChange={(e) => setNewProperty({ ...newProperty, key: e.target.value })}
                    onKeyDown={handleKeyPress}
                    placeholder="Enter key name"
                    size="small"
                    sx={{ mr: 1, flex: 1 }}
                />
                <TextField
                    label="Add New Value"
                    variant="filled"
                    value={newProperty.value}
                    onChange={(e) => setNewProperty({ ...newProperty, value: e.target.value })}
                    onKeyDown={handleKeyPress}
                    placeholder="Enter value"
                    size="small"
                    sx={{ mr: 1, flex: 1 }}
                />
                <IconButton onClick={handleAddNewProperty} size="small">
                    <AddIcon />
                </IconButton>
            </Box>
        </Box>
    );
};
export default PropertiesEditBox;
