import { Box } from "@mui/material";
import React from "react";
import { IAgentMsg } from "../../../models/interfaces/workbench/agent/IAgentMsg";
import { IChatParticipant } from "../../../models/interfaces/workbench/chat/IChatParticipant";
import { IMessage } from "../../../models/interfaces/workbench/chat/IMessage";
import { MessageTypeEnum } from "../../../models/interfaces/workbench/chat/MessageTypeEnum";
import { ActionStatusEnum } from "../../../types/action/ActionStatusEnum";
import { actionMessageStyle } from "./ChatMessagesStyle";

interface IChatMessageAgentActionProp {
    chatParticipantProfiles: Map<string, IChatParticipant>;
    message: IMessage;
    index: number;
}

export const ChatMessageAgentAction: React.FC<IChatMessageAgentActionProp> = ({
    chatParticipantProfiles,
    message,
    index,
}: IChatMessageAgentActionProp) => {
    const fromUser = chatParticipantProfiles.get(message.fromUserId);
    const agentAction = JSON.parse(message.llmMessage.content) as IAgentMsg;
    const isCallingAgentAction =
        message.messageType === MessageTypeEnum.AgentAction &&
        agentAction.action?.actionStatus === ActionStatusEnum.Initiated;
    const isRunningAgentAction =
        message.messageType === MessageTypeEnum.AgentAction &&
        agentAction.action?.actionStatus === ActionStatusEnum.InProgress;
    return (
        <Box key={index} sx={actionMessageStyle}>
            {isCallingAgentAction ? (
                <Box>
                    <Box component="span" sx={{ fontWeight: "bold" }}>
                        [{fromUser?.participantName}]
                    </Box>{" "}
                    is calling action [{agentAction.action?.actionName}] on{" "}
                    <Box component="span" sx={{ fontWeight: "bold" }}>
                        {chatParticipantProfiles.get(message.toUserIds[0])?.participantName}
                    </Box>
                    ...
                </Box>
            ) : isRunningAgentAction ? (
                <Box>
                    [{agentAction.action?.actionName}] is running on{" "}
                    <Box component="span" sx={{ fontWeight: "bold" }}>
                        {chatParticipantProfiles.get(message.toUserIds[0])?.participantName}
                    </Box>
                    ...
                </Box>
            ) : (
                // NOTE: This is tricky. The returned msg is from the action performer to the action initiator
                <Box>
                    <Box component="span" sx={{ fontWeight: "bold" }}>
                        {chatParticipantProfiles.get(message.fromUserId)?.participantName}
                    </Box>{" "}
                    is reporting result of action [{agentAction.action?.actionName}] to{" "}
                    <Box component="span" sx={{ fontWeight: "bold" }}>
                        {chatParticipantProfiles.get(message.toUserIds[0])?.participantName}
                    </Box>
                    ...
                </Box>
            )}
        </Box>
    );
};
