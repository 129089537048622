// The MUI version of ./Chatbox.module.css
import { SxProps, Theme } from "@mui/material";

const chatAreaStyle: SxProps<Theme> = {
    position: "relative",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    gap: 0,
    "& > *": {
        flexShrink: 0,
    },
    "& > :first-of-type": {
        flexGrow: 1,
        flexShrink: 1,
        minHeight: 0,
        overflow: "hidden",
    },
    "& > :last-child": {
        mt: 1,
    },
};

const agentStatusBoxStyle: SxProps<Theme> = {
    bgcolor: "#2C2C2C",
    color: "#E0E0E0",
    flex: 1,
    width: "100%",
    overflowY: "auto",
    borderRadius: 1,
    border: "1px solid #404040",
    p: 2,
    display: "flex",
    flexDirection: "column",
    height: "calc(100vh - 300px)",
    "&::-webkit-scrollbar": {
        width: "8px",
        height: "8px",
    },
    "&::-webkit-scrollbar-track": {
        bgcolor: "#1E1E1E",
    },
    "&::-webkit-scrollbar-thumb": {
        bgcolor: "#404040",
        borderRadius: "4px",
        "&:hover": {
            bgcolor: "#666666",
        },
    },
};

const agentStatusStyle: SxProps<Theme> = {
    mb: 2,
    display: "flex",
    alignItems: "center",
    color: "#E0E0E0",
    transition: "all 0.2s ease-in-out",
    "&:hover": {
        color: "#FFFFFF",
    },
};

const toggleContainerStyle: SxProps<Theme> = {
    bgcolor: "#2C2C2C",
    p: 2,
    mb: 2,
    borderRadius: 1,
    border: "1px solid #404040",
    width: "100%",
};

const toggleLabelStyle: SxProps<Theme> = {
    "& .MuiFormControlLabel-label": {
        color: "#E0E0E0",
        fontSize: "0.875rem",
    },
};

export { agentStatusBoxStyle, agentStatusStyle, chatAreaStyle, toggleContainerStyle, toggleLabelStyle };
