// This file is used to store the environment variables that are used in the application.

// Auth0 settings
const REACT_APP_AUTH0_DOMAIN = process.env.REACT_APP_AUTH0_DOMAIN as string;
const REACT_APP_AUTH0_CLIENT_ID = process.env.REACT_APP_AUTH0_CLIENT_ID as string;
const REACT_APP_AUTH0_AUDIENCE = process.env.REACT_APP_AUTH0_AUDIENCE as string;
const REACT_APP_AUTH0_REDIRECT_URI = process.env.REACT_APP_AUTH0_REDIRECT_URI as string;

// Application Insights
const REACT_APP_APPINSIGHTS_INSTRUMENTATIONKEY = process.env.REACT_APP_APPINSIGHTS_INSTRUMENTATIONKEY as string;

// Knowledge index search apis
const REACT_APP_BACKEND_PROMPT_INDEX_URL = process.env.REACT_APP_BACKEND_PROMPT_INDEX_URL as string;
const REACT_APP_BACKEND_SENTENCE_INDEX_URL = process.env.REACT_APP_BACKEND_SENTENCE_INDEX_URL as string;
const REACT_APP_BACKEND_SOLUTION_INDEX_URL = process.env.REACT_APP_BACKEND_SOLUTION_INDEX_URL as string;

// Backend urls
const REACT_APP_BACKEND_BASE_URL = process.env.REACT_APP_BACKEND_BASE_URL as string;
const REACT_APP_BACKEND_PROTECTED_URL = `${REACT_APP_BACKEND_BASE_URL}/protected`;
const REACT_APP_BACKEND_PUBLIC_URL = `${REACT_APP_BACKEND_BASE_URL}/public`;
const REACT_APP_BACKEND_SOCKET_URL = process.env.REACT_APP_BACKEND_SOCKET_URL as string;

// API keys
const REACT_APP_API_KEY = process.env.REACT_APP_API_KEY as string;

export {
    REACT_APP_API_KEY,
    REACT_APP_APPINSIGHTS_INSTRUMENTATIONKEY,
    REACT_APP_AUTH0_AUDIENCE,
    REACT_APP_AUTH0_CLIENT_ID,
    REACT_APP_AUTH0_DOMAIN,
    REACT_APP_AUTH0_REDIRECT_URI,
    REACT_APP_BACKEND_BASE_URL,
    REACT_APP_BACKEND_PROMPT_INDEX_URL,
    REACT_APP_BACKEND_PROTECTED_URL,
    REACT_APP_BACKEND_PUBLIC_URL,
    REACT_APP_BACKEND_SENTENCE_INDEX_URL,
    REACT_APP_BACKEND_SOCKET_URL,
    REACT_APP_BACKEND_SOLUTION_INDEX_URL,
};
