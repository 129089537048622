import { useAuth0 } from "@auth0/auth0-react";
import DeleteIcon from "@mui/icons-material/Delete";
import {
    Alert,
    Box,
    Button,
    CircularProgress,
    Container,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    IconButton,
    List,
    ListItem,
    ListItemText,
    Paper,
    Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { REACT_APP_BACKEND_PROTECTED_URL } from "../configs/config";
import IRoutineTask from "../models/interfaces/workbench/agent/IRoutineTask";
const Schedule = (): React.ReactElement => {
    const { getAccessTokenSilently } = useAuth0();
    const [tasks, setTasks] = useState<IRoutineTask[]>([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [taskIdToDelete, setTaskIdToDelete] = useState<string>("");

    const fetchTasks = async () => {
        try {
            setLoading(true);
            setError(null);

            // Get the access token
            const token = await getAccessTokenSilently();
            const routineTaskUrl = `${REACT_APP_BACKEND_PROTECTED_URL}/routineTask`;
            const response = await fetch(routineTaskUrl, {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
            });

            const contentType = response.headers.get("content-type");
            if (!contentType || !contentType.includes("application/json")) {
                throw new Error("Server returned non-JSON response. Please check your API endpoint configuration.");
            }

            if (!response.ok) {
                if (response.status === 401) {
                    throw new Error("Please log in to view your tasks");
                }
                throw new Error(`Server returned ${response.status}: ${response.statusText}`);
            }

            const data = await response.json();
            setTasks(data);
        } catch (err) {
            console.error("Error fetching tasks:", err);
            setError(err instanceof Error ? err.message : "An error occurred while fetching tasks");
        } finally {
            setLoading(false);
        }
    };

    const handleDeleteClick = (taskId: string) => {
        setTaskIdToDelete(taskId);
        setDeleteDialogOpen(true);
    };

    const handleDeleteConfirm = async (taskIdToDelete: string) => {
        try {
            setError(null);

            // Get the access token
            const token = await getAccessTokenSilently();
            const routineTaskUrl = `${REACT_APP_BACKEND_PROTECTED_URL}/routineTask/${taskIdToDelete}`;
            const response = await fetch(routineTaskUrl, {
                method: "DELETE",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
            });

            if (!response.ok) {
                if (response.status === 401) {
                    throw new Error("Please log in to delete tasks");
                }
                throw new Error(`Failed to delete task: ${response.statusText}`);
            }

            // Remove the deleted task from the state
            setTasks(tasks.filter((task) => task.taskId !== taskIdToDelete));
            setDeleteDialogOpen(false);
        } catch (err) {
            console.error("Error deleting task:", err);
            setError(err instanceof Error ? err.message : "An error occurred while deleting the task");
        }
    };

    const handleDeleteCancel = () => {
        setDeleteDialogOpen(false);
    };

    useEffect(() => {
        fetchTasks();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (loading) {
        return (
            <Container maxWidth="lg" sx={{ mt: 4, mb: 4, display: "flex", justifyContent: "center" }}>
                <CircularProgress />
            </Container>
        );
    }

    return (
        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <Typography variant="h4" component="h1" gutterBottom>
                My Schedule
            </Typography>

            {error && (
                <Alert severity="error" sx={{ mb: 2 }}>
                    {error}
                </Alert>
            )}

            <Paper elevation={3} sx={{ p: 3 }}>
                {tasks.length === 0 ? (
                    <Box sx={{ textAlign: "center", py: 3 }}>
                        <Typography color="text.secondary">No scheduled tasks found</Typography>
                    </Box>
                ) : (
                    <List>
                        {tasks.map((task, index) => (
                            <React.Fragment key={task.taskId}>
                                <ListItem
                                    alignItems="flex-start"
                                    secondaryAction={
                                        <IconButton
                                            edge="end"
                                            aria-label="delete"
                                            onClick={() => handleDeleteClick(task.taskId)}
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    }
                                >
                                    <ListItemText
                                        primary={task.action.actionName}
                                        secondary={
                                            <React.Fragment>
                                                <Typography component="span" variant="body2" color="text.primary">
                                                    {new Date(task.lastRunAt).toLocaleString()}
                                                </Typography>
                                                {" — Frequency: "}
                                                <Typography component="span" variant="body2">
                                                    {task.intervalSec} seconds
                                                </Typography>
                                                {" — ID: "}
                                                <Typography component="span" variant="body2">
                                                    {task.taskId}
                                                </Typography>
                                                {" — "}
                                                {task.taskRequirements}
                                            </React.Fragment>
                                        }
                                    />
                                </ListItem>
                                {index < tasks.length - 1 && <Divider />}
                            </React.Fragment>
                        ))}
                    </List>
                )}
            </Paper>

            {/* Delete Confirmation Dialog */}
            <Dialog open={deleteDialogOpen} onClose={handleDeleteCancel}>
                <DialogTitle>Delete Task</DialogTitle>
                <DialogContent>Are you sure you want to delete this task?</DialogContent>
                <DialogActions>
                    <Button onClick={handleDeleteCancel}>Cancel</Button>
                    <Button onClick={() => handleDeleteConfirm(taskIdToDelete)} color="error">
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </Container>
    );
};

export default Schedule;
