import { Box, CircularProgress, Paper } from "@mui/material";
import React, { useEffect } from "react";
import { IChatParticipant } from "../../../models/interfaces/workbench/chat/IChatParticipant";
import { IConversation } from "../../../models/interfaces/workbench/chat/IChatSession";
import { MessageTypeEnum } from "../../../models/interfaces/workbench/chat/MessageTypeEnum";
import { ChatInitStateEnum } from "../../../types/ChatInitStateEnum";
import { ChatMessage } from "./ChatMessage";
import { ChatMessageAgentAction } from "./ChatMessageAgentAction";
import { chatMessageBoxStyle } from "./ChatMessagesStyle";

interface IChatMessageWindowProp {
    conversation: IConversation | undefined;
    chatParticipantProfiles: Map<string, IChatParticipant>;
    endOfChatRef: React.RefObject<HTMLDivElement>;
    showAgentActionDetails: boolean;
    isLoading?: boolean;
    isInitializing: ChatInitStateEnum;
}

export const ChatMessages: React.FC<IChatMessageWindowProp> = ({
    conversation,
    chatParticipantProfiles,
    endOfChatRef,
    showAgentActionDetails,
    isInitializing,
    isLoading = false,
}: IChatMessageWindowProp) => {
    // Add effect to scroll to bottom when messages change
    useEffect(() => {
        if (endOfChatRef.current) {
            // Get the Box element which is the scrollable container
            const chatContainer = endOfChatRef.current.parentElement;
            if (chatContainer) {
                const isAtBottom =
                    Math.abs(chatContainer.scrollHeight - chatContainer.scrollTop - chatContainer.clientHeight) < 100;

                if (isAtBottom || !conversation?.messageHistory?.length) {
                    chatContainer.scrollTo({
                        top: chatContainer.scrollHeight,
                        behavior: "smooth",
                    });
                }
            }
        }
    }, [conversation?.messageHistory?.length, endOfChatRef, isLoading]);

    const renderContent = () => {
        switch (isInitializing) {
            case ChatInitStateEnum.Inactive:
                return (
                    <Paper elevation={2}>
                        <Box
                            sx={{
                                ...chatMessageBoxStyle,
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                gap: 2,
                            }}
                        >
                            <Box component="span" sx={{ color: "text.secondary" }}>
                                Chat is inactive. Start a new conversation
                            </Box>
                        </Box>
                    </Paper>
                );
            case ChatInitStateEnum.Initializing:
                return (
                    <Paper elevation={2}>
                        <Box
                            sx={{
                                ...chatMessageBoxStyle,
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                gap: 2,
                            }}
                        >
                            <Box component="span" sx={{ color: "text.secondary" }}>
                                Initializing chat...
                            </Box>
                            <CircularProgress size={20} color="inherit" />
                        </Box>
                    </Paper>
                );
            case ChatInitStateEnum.Active:
            default:
                return (
                    <Paper elevation={2}>
                        <Box sx={chatMessageBoxStyle}>
                            {conversation?.messageHistory?.map((message, index) => (
                                <Box component="div" key={index}>
                                    {!showAgentActionDetails && message.messageType === MessageTypeEnum.AgentAction ? (
                                        <ChatMessageAgentAction
                                            chatParticipantProfiles={chatParticipantProfiles}
                                            message={message}
                                            index={index}
                                        />
                                    ) : (
                                        <ChatMessage
                                            chatParticipantProfiles={chatParticipantProfiles}
                                            message={message}
                                            index={index}
                                        />
                                    )}
                                </Box>
                            ))}
                            <Box ref={endOfChatRef} sx={{ float: "left", clear: "both" }} />
                        </Box>
                    </Paper>
                );
        }
    };

    return renderContent();
};
