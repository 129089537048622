import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Button, Typography } from "@mui/material";
import React from "react";
import { type Socket } from "socket.io-client";
import { IAgent } from "../../../../models/interfaces/workbench/agent/IAgent";
import { IAgentInstance } from "../../../../models/interfaces/workbench/agent/IAgentInstance";
import { ChatInitStateEnum } from "../../../../types/ChatInitStateEnum";
import { killChat } from "../../ManageConversation";
import { agentStatusStyle } from "../ChatboxStyle";

interface ActiveAgentListProps {
    socket: Socket | undefined;
    chatSessionId: string;
    activeAgents: IAgentInstance[];
    setIsSelectAgentModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
    selectedAgents: { [key: string]: IAgent };
    startNewConversationAsync: (
        agentList: {
            [agentId: string]: number;
        },
        systemPrompt: string
    ) => Promise<void>;
    systemPrompt: string;
    setIsInitializing: React.Dispatch<React.SetStateAction<ChatInitStateEnum>>;
}

const ActiveAgentList: React.FC<ActiveAgentListProps> = ({
    socket,
    activeAgents,
    chatSessionId,
    setIsSelectAgentModalOpen,
    selectedAgents,
    startNewConversationAsync,
    systemPrompt,
    setIsInitializing,
}) => {
    return (
        <>
            <Typography sx={{ fontSize: "1.1rem", fontWeight: 500, color: "#E0E0E0", mb: 2 }}>Active Agents</Typography>
            <Button
                variant="contained"
                color="secondary"
                fullWidth
                sx={{ mb: 2 }}
                onClick={() => {
                    setIsSelectAgentModalOpen(true);
                }}
            >
                Invite Agent
            </Button>
            <Button
                variant="contained"
                color="error"
                fullWidth
                sx={{ mb: 2 }}
                onClick={() => {
                    killChat({
                        socket: socket,
                        chatSessionId: chatSessionId,
                    });
                }}
            >
                Kill Chat
            </Button>
            <Button
                variant="contained"
                color="warning"
                fullWidth
                sx={{ mb: 2 }}
                onClick={async () => {
                    setIsInitializing(ChatInitStateEnum.Initializing);
                    await startNewConversationAsync(
                        Object.values(selectedAgents)
                            .map((x) => {
                                return {
                                    agentId: x.agentId,
                                    versionId: x.versionId,
                                };
                            })
                            .reduce((acc, x) => {
                                acc[x.agentId] = x.versionId;
                                return acc;
                            }, {} as { [agentId: string]: number }),
                        systemPrompt
                    );
                }}
            >
                Restart Chat
            </Button>

            {activeAgents === undefined || activeAgents == null || activeAgents.length === 0 ? (
                <Typography sx={{ ...agentStatusStyle, color: "#B0B0B0" }}>No active agents</Typography>
            ) : (
                activeAgents?.map((agent, index) => (
                    <Typography key={index} sx={agentStatusStyle}>
                        <CheckCircleIcon
                            fontSize="small"
                            sx={{
                                verticalAlign: "middle",
                                fontSize: "small",
                                mr: 1,
                                color: "#4caf50",
                            }}
                        />
                        {agent["agentName"]}
                    </Typography>
                ))
            )}
        </>
    );
};

export default ActiveAgentList;
