import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ComputerIcon from "@mui/icons-material/Computer";
import DownloadIcon from "@mui/icons-material/Download";
import {
    Alert,
    Box,
    Button,
    Card,
    CardContent,
    Container,
    Dialog,
    Grid,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Typography,
} from "@mui/material";
import React from "react";

const Download: React.FC = () => {
    const [showMacError, setShowMacError] = React.useState(false);

    const features = [
        "Visual AI automation - create and execute tasks with simple instructions",
        "Intelligent context retention for smarter, more consistent results",
        "Seamless workflow integration with your existing tools",
        "Enterprise-grade security with local execution guarantee",
    ];

    const handleDownload = (platform: string) => {
        if (platform === "mac") {
            setShowMacError(true);
            return;
        }

        let url = "";
        if (platform === "windows") {
            url =
                "https://firebasestorage.googleapis.com/v0/b/muggle-ai-action-script-uswest1/o/public%2Fstudio%2Fmuggle-ai-studio-win.zip?alt=media&token=dafa7375-52a8-496d-b1aa-f51a4fa214ce";
        } else if (platform === "linux") {
            url =
                "https://firebasestorage.googleapis.com/v0/b/muggle-ai-action-script-uswest1/o/public%2Fstudio%2Fmuggle-ai-studio-linux.zip?alt=media&token=373c3932-929d-4e00-bcf3-0588190f7678";
        }
        window.open(url, "_blank");
    };

    const handleCloseError = () => {
        setShowMacError(false);
    };

    return (
        <Container maxWidth="lg" sx={{ mt: 8, mb: 8 }}>
            <Dialog
                open={showMacError}
                onClose={handleCloseError}
                PaperProps={{
                    sx: {
                        maxWidth: "400px",
                        width: "100%",
                        m: 0,
                    },
                }}
            >
                <Alert
                    onClose={handleCloseError}
                    severity="info"
                    sx={{
                        backgroundColor: "#1976d2",
                        color: "white",
                        "& .MuiAlert-icon": {
                            color: "white",
                        },
                        fontSize: "1rem",
                    }}
                >
                    macOS version is not supported yet. Stay tuned for updates!
                </Alert>
            </Dialog>
            <Box sx={{ textAlign: "center", mb: 6 }}>
                <Typography variant="h2" component="h1" gutterBottom>
                    Download Muggle AI Studio
                </Typography>
                <Typography variant="h5" color="text.secondary" paragraph>
                    Empower your workflow with customizable AI automation
                </Typography>
            </Box>

            <Grid container spacing={4}>
                <Grid item xs={12} md={8}>
                    <Card sx={{ height: "100%" }}>
                        <CardContent>
                            <Typography variant="h5" gutterBottom>
                                Key Features
                            </Typography>
                            <List>
                                {features.map((feature, index) => (
                                    <ListItem key={index}>
                                        <ListItemIcon>
                                            <CheckCircleOutlineIcon color="primary" />
                                        </ListItemIcon>
                                        <ListItemText primary={feature} />
                                    </ListItem>
                                ))}
                            </List>
                        </CardContent>
                    </Card>
                </Grid>

                <Grid item xs={12} md={4}>
                    <Card sx={{ height: "100%" }}>
                        <CardContent>
                            <Typography variant="h5" gutterBottom>
                                Download Now
                            </Typography>
                            <Box sx={{ mt: 2 }}>
                                <Button
                                    variant="contained"
                                    size="large"
                                    fullWidth
                                    startIcon={<DownloadIcon />}
                                    onClick={() => handleDownload("windows")}
                                    sx={{ mb: 2 }}
                                >
                                    Download for Windows
                                </Button>
                                <Button
                                    variant="contained"
                                    size="large"
                                    fullWidth
                                    startIcon={<DownloadIcon />}
                                    onClick={() => handleDownload("mac")}
                                    sx={{ mb: 2 }}
                                >
                                    Download for macOS
                                </Button>
                                <Button
                                    variant="contained"
                                    size="large"
                                    fullWidth
                                    startIcon={<DownloadIcon />}
                                    onClick={() => handleDownload("linux")}
                                >
                                    Download for Linux
                                </Button>
                            </Box>
                            <Typography variant="body2" color="text.secondary" sx={{ mt: 2 }}>
                                Version 1.0.0 | Released: {new Date().toLocaleDateString()}
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>

            <Box sx={{ mt: 6 }}>
                <Typography variant="h4" gutterBottom>
                    System Requirements
                </Typography>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={4}>
                        <Card>
                            <CardContent>
                                <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                                    <ComputerIcon sx={{ mr: 1 }} />
                                    <Typography variant="h6">Windows</Typography>
                                </Box>
                                <Typography variant="body2">
                                    • Windows 10 64-bit or later
                                    <br />
                                    • 8GB RAM minimum
                                    <br />
                                    • 4GB free disk space
                                    <br />• Modern processor
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Card>
                            <CardContent>
                                <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                                    <ComputerIcon sx={{ mr: 1 }} />
                                    <Typography variant="h6">macOS</Typography>
                                </Box>
                                <Typography variant="body2">
                                    • macOS 11.0 or later
                                    <br />
                                    • 8GB RAM minimum
                                    <br />
                                    • 4GB free disk space
                                    <br />• Apple Silicon or Intel processor
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Card>
                            <CardContent>
                                <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                                    <ComputerIcon sx={{ mr: 1 }} />
                                    <Typography variant="h6">Linux</Typography>
                                </Box>
                                <Typography variant="body2">
                                    • Ubuntu 20.04 or equivalent
                                    <br />
                                    • 8GB RAM minimum
                                    <br />
                                    • 4GB free disk space
                                    <br />• Modern x86_64 processor
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Box>
        </Container>
    );
};

export default Download;
