import { useAuth0 } from "@auth0/auth0-react";
import CloseIcon from "@mui/icons-material/Close";
import {
    Box,
    Button,
    CircularProgress,
    Divider,
    FormControl,
    FormControlLabel,
    FormGroup,
    IconButton,
    Modal,
    Paper,
    Typography,
} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import React, { useEffect, useState } from "react";
import { type Socket } from "socket.io-client";
import { REACT_APP_BACKEND_PROTECTED_URL } from "../../../configs/config";
import { IAgent } from "../../../models/interfaces/workbench/agent/IAgent";
import { IConversation } from "../../../models/interfaces/workbench/chat/IChatSession";
import GetRequestHeader from "../../../utils/auth_utils";
import { agentList, modalBoxStyle } from "./SelectAgentModalStyle";

interface SelectAgentModalProps {
    props: {
        userId: string;
        isSelectAgentModalOpen: boolean;
        setIsSelectAgentModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
        setConversation: React.Dispatch<React.SetStateAction<IConversation>>;
        selectedAgents: { [key: string]: IAgent };
        setSelectedAgents: React.Dispatch<React.SetStateAction<{ [key: string]: IAgent }>>;
        socket: Socket | undefined;
        chatSessionId: string;
        systemPrompt: string;
        startNewConversationAsync: (agentList: { [agentId: string]: number }, systemPrompt: string) => Promise<void>;
    };
}

const SelectAgentModal: React.FC<SelectAgentModalProps> = ({ props }) => {
    const [availableAgentsRequest, setAvailableAgentsRequest] = useState({
        loading: false,
        availableAgents: [] as IAgent[],
        error: null as unknown as Error,
    });

    const { getAccessTokenSilently } = useAuth0();

    // const url = `${BACKEND_BASE_URL}/prompt/filter`;
    const url = `${REACT_APP_BACKEND_PROTECTED_URL}/agent/filter`;

    useEffect(() => {
        const fetchAvailableAgents = async () => {
            try {
                if (props.isSelectAgentModalOpen === false) return;
                setAvailableAgentsRequest((prevState) => {
                    return {
                        ...prevState,
                        loading: true,
                    };
                });
                const GetOptions = async () =>
                    GetRequestHeader({
                        method: "GET",
                        authToken: await getAccessTokenSilently(),
                    });
                const resp = await fetch(url, await GetOptions());
                if (!resp.ok) throw new Error("API request failed: " + resp.statusText);
                const data = await resp.json();
                setAvailableAgentsRequest((prevState) => {
                    return {
                        ...prevState,
                        loading: false,
                        availableAgents: (data as IAgent[]).sort((a, b) => {
                            return a.agentName.localeCompare(b.agentName);
                        }),
                        error: null as unknown as Error,
                    };
                });
            } catch (error: Error | any) {
                console.log(error);
                setAvailableAgentsRequest((prevState) => {
                    return {
                        ...prevState,
                        availableAgents: [],
                        loading: false,
                        error: error,
                    };
                });
            }
        };
        fetchAvailableAgents();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.isSelectAgentModalOpen, url]);

    const handleAgentSelection = (index: number) => {
        if (index === null || index === undefined) return;
        const agentId = availableAgentsRequest.availableAgents[index].agentId;
        if (props.selectedAgents[agentId] === undefined) {
            props.setSelectedAgents((prevState) => {
                return {
                    ...prevState,
                    [agentId]: availableAgentsRequest.availableAgents[index],
                };
            });
        } else {
            props.setSelectedAgents((prevState) => {
                const newState = { ...prevState };
                delete newState[agentId];
                return newState;
            });
        }
    };

    const handleSubmitSelectedAgents = async () => {
        try {
            const selectedAgents = Object.values(props.selectedAgents)
                .map((x) => {
                    return {
                        agentId: x.agentId,
                        versionId: x.versionId,
                    };
                })
                .reduce((acc, x) => {
                    acc[x.agentId] = x.versionId;
                    return acc;
                }, {} as { [agentId: string]: number });
            await props.startNewConversationAsync(selectedAgents, props.systemPrompt);
            props.setIsSelectAgentModalOpen(false);
        } catch (error) {
            console.error("An error occurred:", error);
        }
    };

    return (
        <Modal
            open={props.isSelectAgentModalOpen}
            onClose={() => props.setIsSelectAgentModalOpen(false)}
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
        >
            <Paper
                sx={{
                    ...modalBoxStyle,
                    borderRadius: 2,
                    border: "none",
                }}
            >
                <Box sx={{ position: "relative", mb: 3 }}>
                    <Typography
                        id="modal-title"
                        variant="h5"
                        component="h2"
                        sx={{
                            fontWeight: 500,
                            color: "text.primary",
                        }}
                    >
                        Invite Agent(s)
                    </Typography>
                    <IconButton
                        sx={{
                            position: "absolute",
                            right: -8,
                            top: -8,
                            color: "text.secondary",
                        }}
                        onClick={() => props.setIsSelectAgentModalOpen(false)}
                    >
                        <CloseIcon />
                    </IconButton>
                </Box>

                <Divider sx={{ mb: 3 }} />

                <Box className="modal-content" sx={{ px: 1 }}>
                    <Paper
                        variant="outlined"
                        sx={{
                            ...agentList,
                            pt: 1,
                            pb: 1,
                            pl: 1,
                            pr: 1.5,
                            mb: 3,
                            backgroundColor: "background.paper",
                            borderColor: "divider",
                        }}
                    >
                        {availableAgentsRequest.loading === true ? (
                            <Box display="flex" justifyContent="center" p={2}>
                                <CircularProgress size={24} />
                            </Box>
                        ) : availableAgentsRequest.error != null && availableAgentsRequest.error !== undefined ? (
                            <Box p={2} color="error.main">
                                Error: {availableAgentsRequest.error.message}
                            </Box>
                        ) : availableAgentsRequest === undefined ||
                          availableAgentsRequest == null ||
                          availableAgentsRequest.availableAgents.length === 0 ? (
                            <Box p={2} color="text.secondary">
                                No available agent
                            </Box>
                        ) : (
                            <FormControl component="fieldset" sx={{ width: "100%" }}>
                                <FormGroup>
                                    {availableAgentsRequest.availableAgents.map((agent: IAgent, index: number) => (
                                        <FormControlLabel
                                            key={agent.agentId}
                                            control={
                                                <Checkbox
                                                    key={agent.agentId}
                                                    name={agent.agentId}
                                                    onChange={() => handleAgentSelection(index)}
                                                    checked={props.selectedAgents[agent.agentId] !== undefined}
                                                    sx={{
                                                        color: "primary.main",
                                                        "&.Mui-checked": {
                                                            color: "primary.main",
                                                        },
                                                        "&:hover": {
                                                            backgroundColor: "rgba(25, 118, 210, 0.04)",
                                                        },
                                                        padding: "4px",
                                                    }}
                                                />
                                            }
                                            label={
                                                <Typography
                                                    variant="body2"
                                                    sx={{
                                                        color:
                                                            props.selectedAgents[agent.agentId] !== undefined
                                                                ? "primary.main"
                                                                : "text.primary",
                                                        fontWeight:
                                                            props.selectedAgents[agent.agentId] !== undefined
                                                                ? 600
                                                                : 400,
                                                        fontSize: "0.875rem",
                                                        lineHeight: 1.2,
                                                    }}
                                                >
                                                    {agent.agentName}
                                                </Typography>
                                            }
                                            sx={{
                                                m: 0,
                                                "&:last-child": { mb: 0 },
                                                "&:hover": {
                                                    backgroundColor: "rgba(25, 118, 210, 0.08)",
                                                    borderRadius: 1,
                                                },
                                                py: 0.25,
                                                px: 0.5,
                                                minHeight: 32,
                                                borderRadius: 1,
                                            }}
                                        />
                                    ))}
                                </FormGroup>
                            </FormControl>
                        )}
                    </Paper>

                    <Box display="flex" justifyContent="flex-end" gap={2}>
                        <Button
                            variant="outlined"
                            onClick={() => props.setIsSelectAgentModalOpen(false)}
                            sx={{ minWidth: 100 }}
                        >
                            Cancel
                        </Button>
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={handleSubmitSelectedAgents}
                            sx={{ minWidth: 100 }}
                        >
                            Invite
                        </Button>
                    </Box>
                </Box>
            </Paper>
        </Modal>
    );
};

export default SelectAgentModal;
