import { Box, Modal, Tab } from "@mui/material";
import React from "react";
import { IAgent } from "../../../models/interfaces/workbench/agent/IAgent";

import { TabContext, TabList, TabPanel } from "@mui/lab";
import { type Socket } from "socket.io-client";
import { IPrompt } from "../../../models/interfaces/prompt/IPrompt";
import { ISolution } from "../../../models/interfaces/solution/ISolution";
import { LoadEntityTypeEnum } from "../../../types/entity/LoadEntityEnum";
import { modalBoxStyle } from "./LoadModalStyle";
import LoadAgent from "./content/LoadAgent";
import LoadPrompt from "./content/LoadPrompt";
import LoadSolution from "./content/LoadSolution";

export interface ILoadModalProps {
    props: {
        userId: string;
        isLoadModalOpen: boolean;
        setIsLoadModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
        currentSolution: ISolution | undefined;
        setCurrentSolution: React.Dispatch<React.SetStateAction<ISolution | undefined>>;
        currentPrompt: IPrompt;
        setCurrentPrompt: React.Dispatch<React.SetStateAction<IPrompt>>;
        currentAgent: IAgent | undefined;
        setCurrentAgent: React.Dispatch<React.SetStateAction<IAgent | undefined>>;
        selectedAgents: { [key: string]: IAgent };
        setSelectedAgents: React.Dispatch<React.SetStateAction<{ [key: string]: IAgent }>>;
        socket: Socket | undefined;
        chatSessionId: string | undefined;
        isAgentPrompt: boolean;
        setIsAgentPrompt: React.Dispatch<React.SetStateAction<boolean>>;
        entityType: LoadEntityTypeEnum;
        setEntityType: React.Dispatch<React.SetStateAction<LoadEntityTypeEnum>>;
        resetStates: () => void;
    };
}

const LoadModal: React.FC<ILoadModalProps> = ({ props }) => {
    const [selectedTabKey, setSelectedTabKey] = React.useState(props.entityType.toString());
    const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
        setSelectedTabKey(newValue);
    };

    return (
        <Modal
            open={props.isLoadModalOpen}
            onClose={() => {
                props.resetStates();
                props.setIsLoadModalOpen(false);
            }}
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
        >
            <Box sx={modalBoxStyle}>
                <TabContext value={selectedTabKey}>
                    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                        <TabList onChange={handleTabChange} aria-label={`Load an entity tab`}>
                            {Object.keys(LoadEntityTypeEnum).map((key) => {
                                return <Tab key={key} label={key} value={key} />;
                            })}
                        </TabList>
                    </Box>
                    <TabPanel value={LoadEntityTypeEnum.Prompt.toString()}>
                        <LoadPrompt
                            props={{
                                userId: props.userId,
                                isLoadModalOpen: props.isLoadModalOpen,
                                setIsLoadModalOpen: props.setIsLoadModalOpen,
                                currentPrompt: props.currentPrompt,
                                setCurrentPrompt: props.setCurrentPrompt,
                                selectedTabKey: selectedTabKey,
                                setEntityType: props.setEntityType,
                            }}
                        />
                    </TabPanel>
                    <TabPanel value={LoadEntityTypeEnum.Agent.toString()}>
                        <LoadAgent
                            props={{
                                userId: props.userId,
                                isLoadModalOpen: props.isLoadModalOpen,
                                setIsLoadModalOpen: props.setIsLoadModalOpen,
                                currentAgent: props.currentAgent,
                                setCurrentAgent: props.setCurrentAgent,
                                setSelectedAgents: props.setSelectedAgents,
                                currentPrompt: props.currentPrompt,
                                setCurrentPrompt: props.setCurrentPrompt,
                                selectedTabKey: selectedTabKey,
                                setEntityType: props.setEntityType,
                            }}
                        />
                    </TabPanel>
                    <TabPanel value={LoadEntityTypeEnum.Solution.toString()}>
                        <LoadSolution
                            props={{
                                userId: props.userId,
                                isLoadModalOpen: props.isLoadModalOpen,
                                setIsLoadModalOpen: props.setIsLoadModalOpen,
                                currentSolution: props.currentSolution,
                                setCurrentSolution: props.setCurrentSolution,
                                selectedTabKey: selectedTabKey,
                                setEntityType: props.setEntityType,
                                setSelectedAgents: props.setSelectedAgents,
                                setCurrentPrompt: props.setCurrentPrompt,
                            }}
                        />
                    </TabPanel>
                </TabContext>
            </Box>
        </Modal>
    );
};

export default LoadModal;
