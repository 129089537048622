import { useAuth0 } from "@auth0/auth0-react";
import { Box } from "@mui/material";
import * as React from "react";
import { IPrompt } from "../../../models/interfaces/prompt/IPrompt";
import { AgentFunctionRoleEnum } from "../../../models/interfaces/workbench/agent/AgentFunctionRoleEnum";
import { IAgent } from "../../../models/interfaces/workbench/agent/IAgent";
import { IAgentPromptView } from "../../../models/interfaces/workbench/agent/IAgentPromptView";
import { LoadEntityTypeEnum } from "../../../types/entity/LoadEntityEnum";
import { ResetAgentVersion } from "../../agent/ManageAgent";
import { ListAgentVersionsOptions, ListAgentVersionsUrl } from "../../prompt/ManagePrompt";
import { VersionTimeline } from "./VersionTimeline";

interface AgentVersionTimelineProps {
    props: {
        checkedAgent: IAgent | undefined;
        setCheckedAgent: React.Dispatch<React.SetStateAction<IAgent | undefined>>;
        currentAgent: IAgent | undefined;
        setCurrentAgent: React.Dispatch<React.SetStateAction<IAgent | undefined>>;
        currentPrompt: IPrompt;
        setCurrentPrompt: React.Dispatch<IPrompt>;
        isSelectVersionModalOpen: boolean;
        setIsSelectVersionModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
        userId: string;
    };
}

const AgentVersionTimeline: React.FC<AgentVersionTimelineProps> = ({ props }: AgentVersionTimelineProps) => {
    const { getAccessTokenSilently } = useAuth0();

    const fetchAvailableVersions = async () => {
        if (props.currentAgent === undefined) return [] as IAgent[];
        const resp = await fetch(
            ListAgentVersionsUrl(props.userId, props.currentAgent.agentId),
            ListAgentVersionsOptions(await getAccessTokenSilently())
        );

        const agentVersions: IAgent[] = await resp.json();
        return agentVersions;
    };

    const selectAgentVersion = async (targetAgent?: IAgent): Promise<void> => {
        if (
            targetAgent === undefined ||
            targetAgent.versionId === undefined ||
            props.currentAgent?.versionId === targetAgent.versionId
        )
            return; // No change

        const agentPromptObj: IAgentPromptView = await ResetAgentVersion(targetAgent, await getAccessTokenSilently());
        const agentObj: IAgent = {
            agentId: agentPromptObj.agentId,
            agentName: agentPromptObj.agentName,
            agentFunctionRole: agentPromptObj.agentFunctionRole as AgentFunctionRoleEnum,
            agentActions: agentPromptObj.agentActions,
            llmEngineName: agentPromptObj.llmEngineName,
            isPrivate: agentPromptObj.isPrivate,
            ownerId: agentPromptObj.ownerId,
            versionId: agentPromptObj.versionId as number,
            creationTimeUtc: agentPromptObj.creationTimeUtc as number,
            creationTimeUtcReadable: agentPromptObj.creationTimeUtcReadable as string,
            promptId: agentPromptObj.prompt.promptId as string,
        };
        props.setCurrentAgent(agentObj);
        props.setCurrentPrompt(agentPromptObj.prompt);
    };

    return (
        <Box id="agent-select">
            <VersionTimeline
                props={{
                    entity: {
                        entityType: LoadEntityTypeEnum.Agent,
                        currentEntity: props.currentAgent,
                        setCurrentEntity: props.setCurrentAgent,
                        getCurrentEntityId: () => {
                            return props.currentAgent?.agentId;
                        },
                        getCurrentEntityName: () => {
                            return props.currentAgent?.agentName;
                        },
                        getCurrentEntityVersionId: () => {
                            return props.currentAgent?.versionId;
                        },
                        fetchAvailableVersions: fetchAvailableVersions,
                        submitSelectVersion: selectAgentVersion,
                    },
                    isSelectVersionModalOpen: props.isSelectVersionModalOpen,
                    setIsSelectVersionModalOpen: props.setIsSelectVersionModalOpen,
                    userId: props.userId,
                }}
            />
        </Box>
    );
};

export { AgentVersionTimeline };
