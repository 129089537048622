import { type SxProps, type Theme } from "@mui/system";

export const titleStyle: SxProps<Theme> = {
    fontSize: "1.75rem",
    fontWeight: 500,
    color: "#E0E0E0",
    display: "flex",
    alignItems: "center",
    gap: 2,
};

export const titleBoxStyle: SxProps<Theme> = {
    display: "flex",
    alignItems: "center",
    gap: 2,
};

export const versionLinkStyle: SxProps<Theme> = {
    fontSize: "1rem",
    color: "#E0E0E0",
    textDecoration: "underline",
    "&:hover": {
        color: "#1976d2",
    },
};

export const containerStyle: SxProps<Theme> = {
    maxWidth: "1920px",
    py: 2,
    px: 2,
    minHeight: "100vh",
};

export const textFieldStyle: SxProps<Theme> = {
    "& .MuiOutlinedInput-root": {
        bgcolor: "#2C2C2C",
        color: "#E0E0E0",
        fontSize: "1rem",
        borderRadius: 1,
        transition: "all 0.2s ease-in-out",

        "& fieldset": {
            borderColor: "#404040",
            borderWidth: 1,
        },
        "&:hover fieldset": {
            borderColor: "#666666",
        },
        "&.Mui-focused fieldset": {
            borderColor: "#1976d2",
            borderWidth: 2,
        },
    },
    "& .MuiInputLabel-root": {
        color: "#B0B0B0",
        "&.Mui-focused": {
            color: "#1976d2",
        },
    },
    "& .MuiInputBase-input": {
        padding: "12px 14px",
        "&::placeholder": {
            color: "#808080",
            opacity: 1,
        },
    },
    width: "100%",
    my: 1,
};

export const buttonBoxStyle: SxProps<Theme> = {
    display: "flex",
    gap: 1,
};

export const buttonStyle: SxProps<Theme> = {
    bgcolor: "#2C2C2C",
    "&:hover": {
        bgcolor: "#3D3D3D",
    },
};

export const sortedByStyle: SxProps<Theme> = {
    variant: "h3",
    color: "#fff",
    textAlign: "end",
    mb: 2,
};

export const cardBoxStyle: SxProps<Theme> = {
    display: "flex",
    flexDirection: { xs: "column", sm: "row" },
    justifyContent: "space-between",
    m: 2,
    gap: 1,
};

export const modalBoxStyle: SxProps<Theme> = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 3,
};
export const modalBuildButton: SxProps<Theme> = {
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "center",
    width: "100%",
    p: 2,
    marginTop: "40%",
    background: "#1976d2", // Material-UI primary color
    //   color: 'white'
};

export const modalCloseButton: SxProps<Theme> = {
    p: 2,
    mt: 0,
    mb: 3,
    marginTop: "0",
    background: "secondary", // Material-UI primary color
    //   color: 'white'
};

export const cardStyle: SxProps<Theme> = {
    width: "100%",
    height: "120px",
    backgroundColor: "#f0f0f0",
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.2)",
    borderRadius: "8px",
    padding: "20px",
    cursor: "pointer",
};

export const sendButton: SxProps<Theme> = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",

    // p: 2,
    marginTop: "10%",
    background: "#1976d2", // Material-UI primary color
    color: "white",
};

export const input: SxProps<Theme> = {
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "center",
    width: "100%",
    mt: 1,
    mb: 1,
    "& .MuiInputBase-root": {
        bgcolor: "#2C2C2C",
        color: "#E0E0E0",
        borderRadius: 1,
        border: "1px solid #404040",
        transition: "all 0.2s ease-in-out",
        "&:hover": {
            borderColor: "#666666",
        },
        "&.Mui-focused": {
            borderColor: "#1976d2",
            boxShadow: "0 0 0 2px rgba(25, 118, 210, 0.2)",
        },
    },
    "& .MuiInputBase-input": {
        padding: "12px 14px",
        "&::placeholder": {
            color: "#808080",
            opacity: 1,
        },
    },
};

export const outputStyle: SxProps<Theme> = {
    width: "auto",
    height: "250px",
    overflow: "auto",
    padding: 2,
    bgcolor: "#2C2C2C",
    color: "#E0E0E0",
    borderRadius: 1,
    border: "1px solid #404040",
    fontFamily: "monospace",
    fontSize: "0.9rem",
    lineHeight: 1.5,
    "&::-webkit-scrollbar": {
        width: "8px",
        height: "8px",
    },
    "&::-webkit-scrollbar-track": {
        bgcolor: "#1E1E1E",
    },
    "&::-webkit-scrollbar-thumb": {
        bgcolor: "#404040",
        borderRadius: "4px",
        "&:hover": {
            bgcolor: "#666666",
        },
    },
};

export const gridStyle: SxProps<Theme> = {
    display: "flex",
    alignItems: "center",
};

export const metricStyle: SxProps<Theme> = {
    backgroundColor: "yellow",
    padding: "10px",
    marginBottom: "5px",
    width: "auto",
    height: "50px",
    overflow: "auto",
};

export const componentGroupStyle: SxProps<Theme> = {
    bgcolor: "#2C2C2C",
    borderRadius: 2,
    p: 3,
    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.2)",
    mb: 1,
};

export const groupHeaderStyle: SxProps<Theme> = {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    mb: 2,
    pb: 2,
    borderBottom: "1px solid #2C2C2C",
};

export const dividerStyle: SxProps<Theme> = {
    my: 6,
    borderColor: "#2C2C2C",
};

export const editPromptGroupStyle: SxProps<Theme> = {
    ...componentGroupStyle,
    mt: 1,
    mb: 1,
};

export const chatGroupStyle: SxProps<Theme> = {
    ...componentGroupStyle,
    mb: 2,
};
