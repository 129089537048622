import { Alert, Box, Button, Container, Grid, Snackbar, Typography } from "@mui/material";
import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import Chatbox from "../../components/chat/chat_box/Chatbox";
import PromptActionButtons from "../../components/workbench/prompt_action/PromptActionButtons";
import PromptEditor from "../../components/workbench/prompt_edit/PromptEditor";
import { IPrompt } from "../../models/interfaces/prompt/IPrompt";
import { IAgent } from "../../models/interfaces/workbench/agent/IAgent";
import type { IConversation } from "../../models/interfaces/workbench/chat/IChatSession";
import { type SectionData } from "../../types/SectionData";
import { StringUtils } from "../../utils/string_utils";

import { useAuth0 } from "@auth0/auth0-react";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import ShareIcon from "@mui/icons-material/Share";
import { GetAgentDefinitionById } from "../../components/agent/ManageAgent";
import { startNewConversation } from "../../components/chat/ManageConversation";
import LoadModal from "../../components/workbench/load_modal/LoadModal";
import { VersionButton } from "../../components/workbench/version_select_model/VersionButton";
import { OpenAIDefaultLLMSettings } from "../../configs/OpenAIDefaultLLMSettings";
import { SocketContext } from "../../context/SocketContext";
import useSolution from "../../hooks/useSolution";
import { PromptTypeEnum } from "../../models/interfaces/prompt/PromptTypeEnum";
import { IAgentInstance } from "../../models/interfaces/workbench/agent/IAgentInstance";
import { ChatSessionStatusEnum } from "../../models/interfaces/workbench/chat/ChatSessionStatusEnum";
import { ChatInitStateEnum } from "../../types/ChatInitStateEnum";
import { LoadEntityTypeEnum } from "../../types/entity/LoadEntityEnum";
import AuthPayload from "../../types/socket/auth_payload";
import { buttonBoxStyle, componentGroupStyle, containerStyle, groupHeaderStyle, titleStyle } from "./WorkbenchStyles";

const DEFAULT_ENTITY_TYPE = LoadEntityTypeEnum.Prompt;

const Workbench: React.FC = () => {
    const { socket } = useContext(SocketContext);
    const { user, getAccessTokenSilently } = useAuth0();
    const [isInitializing, setIsInitializing] = useState(ChatInitStateEnum.Inactive);

    const messagesEndRef = useRef<HTMLDivElement>(null);

    const [snackbar, setSnackbar] = useState<{
        open: boolean;
        message: string;
        severity: "success" | "error";
    }>({
        open: false,
        message: "",
        severity: "success",
    });

    const handleCloseSnackbar = () => {
        setSnackbar({ ...snackbar, open: false });
    };

    const handleShare = () => {
        let entityId = "";
        let entityTypeParam = "";

        switch (entityType) {
            case LoadEntityTypeEnum.Agent:
                entityId = currentAgent?.agentId || "";
                entityTypeParam = "agentId";
                break;
            case LoadEntityTypeEnum.Solution:
                entityId = solution?.solutionId || "";
                entityTypeParam = "solutionId";
                break;
            case LoadEntityTypeEnum.Prompt:
                entityId = currentPrompt?.promptId || "";
                entityTypeParam = "promptId";
                break;
        }

        if (entityId) {
            const url = `${window.location.origin}/chat?${entityTypeParam}=${entityId}`;
            navigator.clipboard
                .writeText(url)
                .then(() => {
                    setSnackbar({
                        open: true,
                        message: `Copied to clipboard: ${url}`,
                        severity: "success",
                    });
                })
                .catch((err) => {
                    console.error("Failed to copy link:", err);
                    setSnackbar({
                        open: true,
                        message: "Failed to copy share link",
                        severity: "error",
                    });
                });
        } else {
            setSnackbar({
                open: true,
                message: "Please save the content first to generate a share link",
                severity: "error",
            });
        }
    };

    const EmptyConversation: IConversation = {
        chatSessionId: "",
        ownerId: "",
        messageHistory: [],
        chatSessionStatus: ChatSessionStatusEnum.Active,
        llmSettings: OpenAIDefaultLLMSettings,
        participantProfiles: [],
    };

    // Handle data passed in from previous page
    const utcNow = Date.now();
    const location = useLocation();
    const searchData = location.state?.searchResult;
    const discoveryData = location.state?.discoveryData;

    const inputEntityType = (searchData?.entityType ||
        discoveryData?.entityType ||
        DEFAULT_ENTITY_TYPE) as LoadEntityTypeEnum;

    const inputSectorsData = searchData?.sector || discoveryData?.sectors || [];

    const inputSolutionId: string =
        inputEntityType === LoadEntityTypeEnum.Solution
            ? searchData?.solutionId || discoveryData?.solution?.solutionId
            : "";

    const inputPromptData =
        searchData !== undefined
            ? ({
                  promptContent: searchData.prompt,
                  promptId: searchData.prompt_id,
                  promptName: "Unknown",
                  promptDescription: "Unknown",
                  promptType: PromptTypeEnum.Prompt,
                  properties: {},
                  tags: [],
                  ownerId: user?.sub,
                  isPrivate: false,
                  isActive: true,
                  versionId: utcNow,
                  creationTimeUtc: utcNow,
                  creationTimeUtcReadable: new Date(utcNow).toLocaleString(),
                  agentId: searchData.agent_id,
                  solutionId: searchData.solution_id,
              } as IPrompt)
            : discoveryData !== undefined
            ? discoveryData.prompt
            : ({
                  promptId: "",
                  promptName: "",
                  promptContent: "",
                  promptDescription: "",
                  promptType: PromptTypeEnum.Prompt,
                  properties: {},
                  tags: [],
                  ownerId: user?.sub,
                  isPrivate: false,
                  isActive: true,
                  versionId: utcNow,
                  creationTimeUtc: utcNow,
                  creationTimeUtcReadable: new Date(utcNow).toLocaleString(),
                  agentId: "",
                  solutionId: "",
              } as IPrompt);

    // For section data
    const [sectionData, setSectionData] = useState<SectionData[]>(inputSectorsData);
    const [conversation, setConversation] = useState<IConversation>(EmptyConversation);
    const [entityType, setEntityType] = useState<LoadEntityTypeEnum>(
        StringUtils.IsStringUndefinedNullOrEmpty(inputEntityType)
            ? LoadEntityTypeEnum.Prompt
            : (inputEntityType as LoadEntityTypeEnum)
    );

    // For prompt input
    const [currentPrompt, setCurrentPrompt] = useState<IPrompt>(inputPromptData);

    // Active agent instances
    const [activeAgentInstances, setActiveAgentInstances] = useState<IAgentInstance[]>([]);

    const setCurrentPromptWithHtmlFreePromptContent = useCallback(
        (textContainsHtmlLineBreak: string, allowHtml: boolean = false) => {
            setCurrentPrompt((prev) => {
                return {
                    ...prev,
                    promptContent: allowHtml
                        ? textContainsHtmlLineBreak
                        : StringUtils.GetHtmlFreePromptContent(textContainsHtmlLineBreak),
                };
            });
        },
        []
    );

    // Keep agent prompt def cached
    const [currentAgent, setCurrentAgent] = useState<IAgent>(); // Current active agent displaying prompt
    const [selectedAgents, setSelectedAgents] = useState<{
        [key: string]: IAgent;
    }>({});

    const { solution, setSolution } = useSolution(inputSolutionId);
    useEffect(() => {
        if (solution?.agents) {
            // Process each agent ID and version number pair
            Promise.all(
                Object.entries(solution.agents).map(async ([agentId, versionId]) => {
                    const agent = await GetAgentDefinitionById(agentId, versionId, await getAccessTokenSilently());
                    return [agentId, agent] as [string, IAgent];
                })
            ).then((agentEntries) => {
                // Convert array of entries to object
                const agentsObject = Object.fromEntries(agentEntries);
                setSelectedAgents(agentsObject);
            });
        }
    }, [solution?.agents, getAccessTokenSilently]);

    const startConversationAsync = async (agentList: { [agentId: string]: number }): Promise<void> => {
        if (socket === undefined) return;
        setIsInitializing(ChatInitStateEnum.Initializing);
        const returnConversation: IConversation = await startNewConversation({
            socket: socket,
            systemPrompt: getPromptOutput(),
            userId: user?.sub ?? "",
            agentList: agentList,
            isButlerRequest: false,
        });
        (socket.auth as AuthPayload).chatSessionId = returnConversation.chatSessionId;
        setConversation(returnConversation);
    };

    const getPromptOutput = () => {
        const sectionStrings = sectionData.map(({ sectionName, sectionElement }) => {
            const sentences = sectionElement.map((e) => e.sentence).join(", ");
            return `${sectionName}: ${sentences}`;
        });
        const sectionDataString = sectionStrings.join("\t");
        const promptOutput = StringUtils.GetHtmlFreePromptContent(
            `${currentPrompt.promptContent}\n${sectionDataString}`
        );
        return promptOutput;
    };

    // LoadModal state
    const [isLoadModalOpen, setIsLoadModalOpen] = useState(false);
    const handleLoadModalOpen = () => {
        setIsLoadModalOpen(true);
    };
    const [isAgentPrompt, setIsAgentPrompt] = React.useState(
        !StringUtils.IsStringUndefinedNullOrEmpty(currentAgent?.agentId)
    );

    // Set initializing to false when conversation is ready
    useEffect(() => {
        if (conversation?.chatSessionId || activeAgentInstances.length > 0) {
            setIsInitializing(ChatInitStateEnum.Active);
        }
    }, [conversation?.chatSessionId, activeAgentInstances.length]);

    const resetStates = () => {
        setCurrentPrompt(inputPromptData);
        setSolution(undefined);
        setActiveAgentInstances([]);
        setCurrentAgent(undefined);
        setSelectedAgents({});
        setIsAgentPrompt(false);
        setConversation(EmptyConversation);
        setSectionData(inputSectorsData);
    };
    return (
        <Container maxWidth="xl" sx={containerStyle}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    {/* Edit Prompt Group */}
                    <Box sx={componentGroupStyle}>
                        <Box sx={groupHeaderStyle}>
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                                <Typography sx={titleStyle}>
                                    {(() => {
                                        let title = `Edit ${entityType}`;
                                        switch (entityType) {
                                            case LoadEntityTypeEnum.Solution: {
                                                if (solution?.solutionName) title = `Edit [${solution.solutionName}]`;
                                                break;
                                            }
                                            case LoadEntityTypeEnum.Agent: {
                                                if (currentAgent?.agentName) title = `Edit [${currentAgent.agentName}]`;
                                                break;
                                            }
                                            case LoadEntityTypeEnum.Prompt: {
                                                if (currentPrompt?.promptName)
                                                    title = `Edit [${currentPrompt.promptName}]`;
                                                break;
                                            }
                                            default:
                                                break;
                                        }
                                        return title;
                                    })()}
                                </Typography>
                                <VersionButton
                                    props={{
                                        isAgentPrompt: isAgentPrompt,
                                        setSelectedAgents: setSelectedAgents,
                                        currentAgent: currentAgent,
                                        setCurrentAgent: setCurrentAgent,
                                        currentPrompt: currentPrompt,
                                        setCurrentPrompt: setCurrentPrompt,
                                        userId: user?.sub ?? "",
                                        chatSessionId: conversation.chatSessionId,
                                        entityType: entityType,
                                        currentSolution: solution,
                                        setCurrentSolution: setSolution,
                                    }}
                                />
                                <Button color="error" variant="contained" onClick={resetStates} sx={{ marginLeft: 2 }}>
                                    Reset Page
                                </Button>
                            </Box>
                            <Box sx={buttonBoxStyle}>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    onClick={handleLoadModalOpen}
                                    sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        gap: "4px",
                                    }}
                                >
                                    Open <OpenInNewIcon fontSize="small" />
                                </Button>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    onClick={handleShare}
                                    sx={{
                                        marginLeft: 1,
                                        display: "flex",
                                        alignItems: "center",
                                        gap: "4px",
                                    }}
                                >
                                    Share <ShareIcon fontSize="small" />
                                </Button>
                            </Box>
                        </Box>

                        <PromptEditor
                            initialSectionData={sectionData}
                            initialPromptId={currentPrompt.promptId}
                            promptContent={currentPrompt.promptContent}
                            setPromptContent={setCurrentPromptWithHtmlFreePromptContent}
                            handleSectionDataChange={setSectionData}
                        />

                        <Box sx={{ mt: 1 }}>
                            <PromptActionButtons
                                startNewConversation={startConversationAsync}
                                userId={user?.sub ?? ""}
                                userName={user?.nickname ?? ""}
                                currentPrompt={currentPrompt}
                                setCurrentPrompt={setCurrentPrompt}
                                currentAgent={currentAgent}
                                setCurrentAgent={setCurrentAgent}
                                selectedAgents={selectedAgents}
                                setSelectedAgents={setSelectedAgents}
                                isAgentPrompt={isAgentPrompt}
                                setIsAgentPrompt={setIsAgentPrompt}
                                entityType={entityType}
                                currentSolution={solution}
                                setCurrentSolution={setSolution}
                            />
                        </Box>
                    </Box>

                    {/* Chat Group */}
                    <Box sx={componentGroupStyle}>
                        <Box sx={groupHeaderStyle}>
                            <Typography sx={titleStyle}>
                                {(() => {
                                    let title = `Chat with ${entityType}`;
                                    switch (entityType) {
                                        case LoadEntityTypeEnum.Solution: {
                                            if (solution?.solutionName) title = `Chat with [${solution.solutionName}]`;
                                            break;
                                        }
                                        case LoadEntityTypeEnum.Agent: {
                                            if (currentAgent?.agentName)
                                                title = `Chat with [${currentAgent.agentName}]`;
                                            break;
                                        }
                                        case LoadEntityTypeEnum.Prompt: {
                                            if (currentPrompt?.promptName)
                                                title = `Chat with [${currentPrompt.promptName}]`;
                                            break;
                                        }
                                        default:
                                            break;
                                    }
                                    return title;
                                })()}
                            </Typography>
                        </Box>

                        <Chatbox
                            socket={socket}
                            userId={user?.sub ?? ""}
                            userName={user?.nickname ?? ""}
                            conversation={conversation}
                            setConversation={setConversation}
                            systemPrompt={getPromptOutput()}
                            startNewConversationAsync={startConversationAsync}
                            selectedAgents={selectedAgents}
                            setSelectedAgents={setSelectedAgents}
                            activeAgentInstances={activeAgentInstances}
                            setActiveAgentInstances={setActiveAgentInstances}
                            messagesEndRef={messagesEndRef}
                            isInitializing={isInitializing}
                            setIsInitializing={setIsInitializing}
                        />
                    </Box>

                    <LoadModal
                        props={{
                            userId: user?.sub ?? "",
                            isLoadModalOpen: isLoadModalOpen,
                            setIsLoadModalOpen: setIsLoadModalOpen,
                            currentSolution: solution,
                            setCurrentSolution: setSolution,
                            currentPrompt: currentPrompt,
                            setCurrentPrompt: setCurrentPrompt,
                            currentAgent: currentAgent,
                            setCurrentAgent: setCurrentAgent,
                            selectedAgents: selectedAgents,
                            setSelectedAgents: setSelectedAgents,
                            socket: socket,
                            chatSessionId: conversation.chatSessionId,
                            isAgentPrompt: isAgentPrompt,
                            setIsAgentPrompt: setIsAgentPrompt,
                            entityType: entityType,
                            setEntityType: setEntityType,
                            resetStates: resetStates,
                        }}
                    />
                </Grid>
            </Grid>
            <Snackbar
                open={snackbar.open}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            >
                <Alert
                    onClose={handleCloseSnackbar}
                    severity={snackbar.severity}
                    variant="filled"
                    sx={{ width: "100%" }}
                >
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </Container>
    );
};

export default Workbench;
