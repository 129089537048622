// A hook to manage solution state

import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from "react";
import { GetSolutionById } from "../components/solution/ManageSolution";
import { ISolution } from "../models/interfaces/solution/ISolution";
import { StringUtils } from "../utils/string_utils";

function useSolution(solutionId: string) {
    const [solution, setSolution] = useState<ISolution>();
    const { getAccessTokenSilently } = useAuth0();
    useEffect(() => {
        if (StringUtils.IsStringUndefinedNullOrEmpty(solutionId)) return;

        getAccessTokenSilently().then((authToken) => {
            if (authToken === undefined || authToken === null || authToken === "")
                throw new Error("Auth token is invalid");
            GetSolutionById(solutionId, authToken)
                .then((sol) => {
                    setSolution(sol);
                })
                .catch((err) => {
                    console.log(err);
                });
        });

        return () => {
            setSolution({} as ISolution);
        };
    });

    return { solution, setSolution };
}

export default useSolution;
