import { AppBar, Box, Button, Toolbar } from "@mui/material";
import { type SxProps, type Theme } from "@mui/system";
import React from "react";
import { Link } from "react-router-dom";
import Logo from "../../assets/logo-text.png";
import LoginLayout from "./LoginState/LoginLayout";
import styles from "./NavigationBar.module.css"; // Import CSS Module

const buttonStyle: SxProps<Theme> = {
    marginRight: "20px", // Increased margin
    color: "inherit",
    display: "flex",
    alignItems: "center",
    height: "100%",
    fontSize: "1.1rem", // Increased font size
    padding: "12px 16px", // Increased padding
};

const toolbarStyle: SxProps<Theme> = {
    display: "flex",
    alignItems: "center",
    height: "96px", // Increased from 64px to 96px (50% taller)
    minHeight: "96px", // Ensure minimum height is also set
};

const appBarStyle: SxProps<Theme> = {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    zIndex: 1100, // MUI's default AppBar z-index
};

// handle menu open and close
const NavigationBar = (): React.ReactElement => {
    const routes = [
        { path: "/", name: "Home" },
        // { path: "/discovery", name: "Discovery" },
        { path: "/workbench", name: "Workbench" },
        { path: "/chat", name: "Chat" },
        { path: "/schedule", name: "My Schedule" },
        { path: "/download", name: "Download" },
    ];

    return (
        <>
            <AppBar sx={appBarStyle}>
                <Toolbar sx={toolbarStyle}>
                    <Box sx={{ flexGrow: 0, display: "flex", alignItems: "center" }}>
                        <Button key={"/"} component={Link} to={"/"} sx={buttonStyle}>
                            <img className={styles.img} src={Logo} alt="MUGGLE AI LOGO" />
                        </Button>
                    </Box>

                    <Box sx={{ flexGrow: 1, display: "flex", alignItems: "center" }}>
                        {routes.map((route) => (
                            <Button key={route.path} component={Link} to={route.path} sx={buttonStyle}>
                                {route.name}
                            </Button>
                        ))}
                    </Box>

                    <Box sx={{ display: "flex", alignItems: "center" }}>
                        <LoginLayout />
                    </Box>
                </Toolbar>
            </AppBar>
            {/* Add a toolbar placeholder with matching height */}
            <Toolbar sx={{ height: "96px", minHeight: "96px" }} />
        </>
    );
};

export default NavigationBar;
