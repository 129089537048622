import CloseIcon from "@mui/icons-material/Close";
import { Box, Button, Dialog, DialogContent, DialogTitle, IconButton, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { type PromptDetailsModalProps } from "../../types/PromptDetailsModalProps";

const PromptDetailsModal: React.FC<PromptDetailsModalProps> = ({
    open,
    onClose,
    prompt,
    examples,
    instructions,
    metrics,
    constraints,
}) => {
    const navigate = useNavigate();
    const handleClick = (): void => {
        navigate("/workbench", {
            state: {
                discoveryData: {
                    prompt,
                    examples,
                    instructions,
                    metrics,
                    constraints,
                },
            },
        });
    };

    return (
        <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
            <DialogTitle>
                {prompt.promptName}
                <IconButton style={{ position: "absolute", right: 8, top: 8 }} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <Box display="flex" height={500}>
                    <Box flex="1" mr={2} overflow="auto" pr={2} borderRight="1px solid gray">
                        <Typography variant="body1" mb={2}>
                            Prompt:
                        </Typography>
                        <Typography>{prompt.promptContent}</Typography>
                    </Box>
                    <Box flex="1" ml={2} overflow="auto" display="flex" flexDirection="column">
                        {/* Properties Section */}
                        <Box flex="1" overflow="auto" mb={2}>
                            <Typography variant="body1" mb={2}>
                                Properties:
                            </Typography>
                            {prompt?.properties
                                ? Object.entries(prompt.properties).map(([prop, value]) => (
                                      <Typography key={prop}>
                                          <strong>{prop}:</strong> {value}
                                      </Typography>
                                  ))
                                : ""}
                        </Box>
                        {/* Tags Section */}
                        <Box flex="1" overflow="auto" mt={2}>
                            <Typography variant="body1" mb={2}>
                                Tags:
                            </Typography>
                            {prompt.tags?.map((tag: string) => (
                                <Typography key={tag}>{tag}</Typography>
                            ))}
                        </Box>
                    </Box>
                </Box>
                <Box mt={2} display="flex" justifyContent="flex-end">
                    <Button variant="contained" color="secondary" onClick={handleClick}>
                        Build with this prompt
                    </Button>
                </Box>
            </DialogContent>
        </Dialog>
    );
};

export default PromptDetailsModal;

