import {
    Button,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
    TextField,
    Tooltip,
} from "@mui/material";
import { type Socket } from "socket.io-client";

import React, { useEffect, useState } from "react";
import { IChatParticipant } from "../../../models/interfaces/workbench/chat/IChatParticipant";
import { ParticipantTypeEnum } from "../../../models/interfaces/workbench/chat/ParticipantTypeEnum";
import { StringUtils } from "../../../utils/string_utils";
import { sendMessage } from "../ManageConversation";
import { inputButtonStyle, inputTextFieldStyle, selectFieldStyle } from "./ChatInputStyle";

interface ChatInputProp {
    socket: Socket | undefined;
    userId: string;
    userName: string;
    chatSessionId: string;
    chatParticipantProfiles: Map<string, IChatParticipant>;
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

const ChatInput: React.FC<ChatInputProp> = ({
    socket,
    userId,
    userName,
    chatSessionId,
    chatParticipantProfiles,
    setIsLoading,
}) => {
    const [inputText, setInputText] = useState("");
    const [loading, setLoading] = useState(false);
    const [sendTo, setSentTo] = React.useState("");
    const [chatParticipants, setChatParticipants] = useState<IChatParticipant[]>(
        Array.from(chatParticipantProfiles.values())
    );

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setInputText(event.target.value);
    };

    const submitNewMessage = async () => {
        if (loading) return;

        if (StringUtils.IsStringUndefinedNullOrEmpty(inputText)) return;
        if (StringUtils.IsStringUndefinedNullOrEmpty(sendTo)) return;
        if (StringUtils.IsStringUndefinedNullOrEmpty(chatSessionId)) return;

        try {
            setLoading(true);
            setIsLoading(true);
            await sendMessage({
                socket: socket,
                chatSessionId: chatSessionId as string,
                userId: userId,
                userName: userName,
                userInputText: inputText,
                sendTo: sendTo,
            });
            setInputText("");
        } catch (error) {
            console.error("An error occurred:", error);
            setIsLoading(false);
        } finally {
            setLoading(false);
        }
    };

    const submitChatMsg = async (event: React.KeyboardEvent<HTMLDivElement>): Promise<void> => {
        if (event.ctrlKey && event.key === "Enter") {
            await submitNewMessage();
            event.preventDefault();
        }
    };

    const handleSendToSelectChange = (event: SelectChangeEvent) => {
        setSentTo(event.target.value);
    };

    useEffect(() => {
        const agArr = Array.from(chatParticipantProfiles.values())
            .filter((x) => x.participantType !== ParticipantTypeEnum.User)
            .sort((a, b) => {
                if (
                    a.participantName?.toLowerCase().includes("assistant") ||
                    a.participantName?.toLowerCase().includes("manager") ||
                    a.participantName?.toLowerCase().includes("planner")
                ) {
                    return -1;
                }
                if (
                    b.participantName?.toLowerCase().includes("assistant") ||
                    b.participantName?.toLowerCase().includes("manager") ||
                    b.participantName?.toLowerCase().includes("planner")
                ) {
                    return 1;
                }
                return a.participantName < b.participantName ? -1 : 1;
            });
        setChatParticipants(agArr);
        if (agArr.length > 0)
            setSentTo((prev: string) => {
                if (prev !== undefined && chatParticipantProfiles.has(prev))
                    // Keep the same agent if it is still in the list
                    return prev;
                return agArr[0].participantId ?? "";
            });
    }, [chatParticipantProfiles]);

    return (
        <Grid container spacing={1} alignItems="flex-start">
            <Grid item xs={2}>
                <FormControl sx={selectFieldStyle} fullWidth>
                    <InputLabel id="send-to-label">Send To</InputLabel>
                    <Select labelId="send-to-label" value={sendTo} onChange={handleSendToSelectChange} label="Send To">
                        {chatParticipants
                            ?.sort((a, b) => (a.participantName < b.participantName ? -1 : 1))
                            .map((x, idx) => (
                                <MenuItem key={idx} value={x.participantId}>
                                    {x.participantName}
                                </MenuItem>
                            ))}
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={9}>
                <TextField
                    multiline
                    minRows={1}
                    maxRows={10}
                    value={inputText}
                    onChange={(event) => handleInputChange(event)}
                    onKeyDown={async (event) => await submitChatMsg(event)}
                    placeholder="Type your message here..."
                    fullWidth
                    sx={inputTextFieldStyle}
                />
            </Grid>
            <Grid item xs={1}>
                <Tooltip title="Press Ctrl + Enter to send" placement="top">
                    <Button
                        variant="contained"
                        color="secondary"
                        fullWidth
                        sx={inputButtonStyle}
                        onClick={async () => await submitNewMessage()}
                        disabled={loading}
                    >
                        {loading ? "Sending..." : "Send"}
                    </Button>
                </Tooltip>
            </Grid>
        </Grid>
    );
};

export default ChatInput;
