enum ClientType {
    WEB = "WEB",
    MOBILE = "MOBILE",
    STUDIO = "STUDIO",
}

export const ClientTypeValues = Object.values(ClientType);

export const ClientTypeKeys = Object.keys(ClientType);

export const ClientTypeMap = new Map<string, ClientType>(
    ClientTypeKeys.map((key) => [key, ClientType[key as keyof typeof ClientType]])
);

export const parseClientType = (clientType: string): ClientType => {
    if (!ClientTypeMap.has(clientType)) {
        throw new Error(`Invalid client type: ${clientType}`);
    }
    return ClientTypeMap.get(clientType) as ClientType;
};

export default ClientType;
