import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { REACT_APP_APPINSIGHTS_INSTRUMENTATIONKEY } from "../configs/config";
let appInsights: ApplicationInsights | null = null;
const isDevelopment = process.env.NODE_ENV === "development";

// Severity levels for logging
export enum LogSeverity {
    Verbose = 0,
    Information = 1,
    Warning = 2,
    Error = 3,
    Critical = 4,
}

// Unified logging interface
interface LogEntry {
    message: string;
    properties?: { [key: string]: any };
    severity?: LogSeverity;
    error?: Error;
}

export const initializeAppInsights = () => {
    // Only initialize if not already initialized
    if (appInsights) return appInsights;

    const instrumentationKey = REACT_APP_APPINSIGHTS_INSTRUMENTATIONKEY;
    if (!instrumentationKey || isDevelopment) {
        console.debug("App Insights instrumentation key is not set");
        return;
    }
    appInsights = new ApplicationInsights({
        config: {
            instrumentationKey: instrumentationKey,
            enableAutoRouteTracking: true,
            enableCorsCorrelation: false,
            enableRequestHeaderTracking: true,
            enableResponseHeaderTracking: true,
            disableFetchTracking: false,
            enableDebug: isDevelopment,
            loggingLevelConsole: isDevelopment ? 1 : 0, // 1 = All logs, 0 = Only critical
        },
    });

    appInsights.loadAppInsights();
    return appInsights;
};

export const getAppInsights = () => appInsights;

// Core logging function
const log = ({ message, properties = {}, severity = LogSeverity.Information, error }: LogEntry) => {
    const timestamp = new Date().toISOString();
    const logData = { timestamp, message, ...properties };

    // Always send to Application Insights
    if (appInsights) {
        if (error) {
            appInsights.trackException({ error, severityLevel: severity, properties: logData });
        } else {
            appInsights.trackTrace({ message, properties: logData, severityLevel: severity });
        }
    }

    // Console logging in development
    if (isDevelopment) {
        const consoleMessage = `[${timestamp}] ${message}`;
        switch (severity) {
            case LogSeverity.Verbose:
                console.debug(consoleMessage, properties);
                break;
            case LogSeverity.Information:
                console.log(consoleMessage, properties);
                break;
            case LogSeverity.Warning:
                console.warn(consoleMessage, properties);
                break;
            case LogSeverity.Error:
            case LogSeverity.Critical:
                console.error(consoleMessage, error || properties);
                break;
            default:
                console.log(consoleMessage, properties);
        }
    }
};

// Public logging functions
export const logDebug = (message: string, properties?: { [key: string]: any }) => {
    log({ message, properties, severity: LogSeverity.Verbose });
};

export const logInfo = (message: string, properties?: { [key: string]: any }) => {
    log({ message, properties, severity: LogSeverity.Information });
};

export const logWarning = (message: string, properties?: { [key: string]: any }) => {
    log({ message, properties, severity: LogSeverity.Warning });
};

export const logError = (message: string, error?: Error, properties?: { [key: string]: any }) => {
    log({ message, error, properties, severity: LogSeverity.Error });
};

export const logCritical = (message: string, error?: Error, properties?: { [key: string]: any }) => {
    log({ message, error, properties, severity: LogSeverity.Critical });
};

// Event tracking
export const logEvent = (name: string, properties?: { [key: string]: any }) => {
    const isDevelopment = process.env.NODE_ENV === "development";

    appInsights?.trackEvent({ name }, properties);

    if (isDevelopment) {
        console.log(`[Event] ${name}`, properties);
    }
};

// Metric tracking
export const logMetric = (name: string, value: number, properties?: { [key: string]: any }) => {
    const isDevelopment = process.env.NODE_ENV === "development";

    appInsights?.trackMetric({ name, average: value }, properties);

    if (isDevelopment) {
        console.log(`[Metric] ${name}: ${value}`, properties);
    }
};
