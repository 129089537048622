import { Timeline, TimelineConnector, TimelineItem, TimelineSeparator } from "@mui/lab";
import {
    Box,
    Button,
    CircularProgress,
    FormControl,
    FormLabel,
    Paper,
    Radio,
    RadioGroup,
    Typography,
} from "@mui/material";
import * as React from "react";
import { useEffect } from "react";
import { EntityVersionTimelineType } from "../../../types/entity/EntityVersionTimelineType";
import { StringUtils } from "../../../utils/string_utils";

interface IVersionTimelineProps<T> {
    props: {
        entity: EntityVersionTimelineType<T>;
        isSelectVersionModalOpen: boolean;
        setIsSelectVersionModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
        userId: string;
    };
}
function VersionTimeline<T>({ props }: IVersionTimelineProps<T>) {
    const [availableVersionRequest, setAvailableVersionRequest] = React.useState({
        loading: false,
        availableOptions: {} as { [key: number]: T },
        error: null as unknown as Error,
    });

    const [selectedVersionId, setSelectedVersionId] = React.useState(props.entity.getCurrentEntityVersionId() ?? 0);

    const onClose = () => {
        console.log(`Current entity version: ${props.entity.getCurrentEntityVersionId()}`);
        setSelectedVersionId(props.entity.getCurrentEntityVersionId() ?? 0);
        props.setIsSelectVersionModalOpen(false);
    };

    useEffect(() => {
        const fetchAvailableVersions = async () => {
            try {
                if (
                    props.isSelectVersionModalOpen === false ||
                    props.entity.currentEntity === undefined ||
                    StringUtils.IsStringUndefinedNullOrEmpty(props.entity.getCurrentEntityId())
                )
                    return;

                setAvailableVersionRequest((prevState) => {
                    return {
                        ...prevState,
                        loading: true,
                    };
                });

                const data: T[] = (await props.entity.fetchAvailableVersions()) ?? [];

                setAvailableVersionRequest((prevState) => {
                    return {
                        ...prevState,
                        loading: false,
                        availableOptions: Object.fromEntries(
                            data
                                .sort((a: any, b: any) => {
                                    const timeA = new Date(a.creationTimeUtc).getTime();
                                    const timeB = new Date(b.creationTimeUtc).getTime();
                                    return timeB - timeA;
                                })
                                .map((v: any) => [v.versionId, v])
                        ),
                        error: null as unknown as Error,
                    };
                });
                setSelectedVersionId(props.entity.getCurrentEntityVersionId() ?? 0);
            } catch (error: Error | any) {
                console.log(error);
                setAvailableVersionRequest((prevState) => {
                    return {
                        ...prevState,
                        availableOptions: {},
                        error: error,
                    };
                });
            } finally {
                setAvailableVersionRequest((prevState) => {
                    return {
                        ...prevState,
                        loading: false,
                    };
                });
            }
        };
        if (props.isSelectVersionModalOpen === true) fetchAvailableVersions();
        else onClose();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.isSelectVersionModalOpen]);

    const handleSubmitSelectedVersion = async () => {
        try {
            if (props.entity === undefined) return; // No entity to rollback
            if (selectedVersionId === undefined || selectedVersionId === props.entity.getCurrentEntityVersionId())
                return; // No version selected

            await props.entity.submitSelectVersion(availableVersionRequest.availableOptions[selectedVersionId]);
            props.setIsSelectVersionModalOpen(false);
        } catch (error) {
            console.error("An error occurred:", error);
        }
    };

    const handleSelectVersionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        console.log(`Current selected version: ${event.target.value}`);
        console.log(`selectedVersionId: ${selectedVersionId}`);
        console.log(`availableVersions: ${Object.keys(availableVersionRequest.availableOptions)}`);
        setSelectedVersionId(parseInt(event.target.value));
    };

    return (
        <Box
            id="version-select"
            sx={{
                width: "100%",
                maxWidth: 800,
                margin: "0 auto",
                p: 3,
            }}
        >
            {availableVersionRequest.loading ? (
                <Box display="flex" justifyContent="center" alignItems="center" minHeight={200}>
                    <CircularProgress />
                </Box>
            ) : availableVersionRequest.error != null && availableVersionRequest.error !== undefined ? (
                <Paper
                    elevation={0}
                    sx={{
                        p: 3,
                        bgcolor: "error.light",
                        color: "error.dark",
                        borderRadius: 2,
                    }}
                >
                    <Typography variant="h6">Error</Typography>
                    <Typography>{availableVersionRequest.error.message}</Typography>
                </Paper>
            ) : availableVersionRequest === undefined ||
              availableVersionRequest == null ||
              Object.keys(availableVersionRequest.availableOptions).length === 0 ? (
                <Box display="flex" justifyContent="center" alignItems="center" minHeight={200}>
                    <Typography variant="h6">NOTE: Please Select a {props.entity.entityType} First.</Typography>
                </Box>
            ) : (
                <FormControl component="fieldset" sx={{ width: "100%" }}>
                    <FormLabel
                        id="version-radio-group"
                        sx={{
                            mb: 3,
                            fontSize: "1.1rem",
                            fontWeight: 500,
                        }}
                    >
                        Available Versions of "{props.entity.getCurrentEntityName()}"
                    </FormLabel>

                    <RadioGroup
                        aria-labelledby="version-radio-group"
                        name="controlled-radio-buttons-group"
                        value={selectedVersionId}
                        onChange={handleSelectVersionChange}
                    >
                        <Box
                            sx={{
                                maxHeight: "400px",
                                overflowY: "auto",
                                "&::-webkit-scrollbar": {
                                    width: "8px",
                                },
                                "&::-webkit-scrollbar-track": {
                                    backgroundColor: "background.paper",
                                    borderRadius: "4px",
                                },
                                "&::-webkit-scrollbar-thumb": {
                                    backgroundColor: "primary.light",
                                    borderRadius: "4px",
                                    "&:hover": {
                                        backgroundColor: "primary.main",
                                    },
                                },
                            }}
                        >
                            <Timeline
                                sx={{
                                    p: 0,
                                    [`& .MuiTimelineItem-root`]: {
                                        minHeight: "auto",
                                        "&:before": {
                                            display: "none",
                                        },
                                    },
                                }}
                            >
                                {Object.keys(availableVersionRequest.availableOptions).map((vStr, index) => {
                                    const versionId = parseInt(vStr);
                                    const version = availableVersionRequest.availableOptions[versionId] as any;
                                    return (
                                        <TimelineItem
                                            key={versionId}
                                            sx={{
                                                display: "flex",
                                                alignItems: "flex-start",
                                                gap: 2,
                                            }}
                                        >
                                            <TimelineSeparator
                                                sx={{
                                                    position: "relative",
                                                    flex: "none",
                                                    mr: 2,
                                                }}
                                            >
                                                <Radio
                                                    size="small"
                                                    value={versionId}
                                                    checked={versionId === selectedVersionId}
                                                />
                                                {index <
                                                    Object.keys(availableVersionRequest.availableOptions).length -
                                                        1 && (
                                                    <TimelineConnector
                                                        sx={{
                                                            my: 1,
                                                            ml: 1.5,
                                                        }}
                                                    />
                                                )}
                                            </TimelineSeparator>
                                            <Box
                                                sx={{
                                                    display: "flex",
                                                    alignItems: "flex-start",
                                                    backgroundColor: "background.paper",
                                                    borderRadius: 1,
                                                    py: 1,
                                                    px: 2,
                                                    boxShadow: 1,
                                                    border: "1px solid",
                                                    borderColor: "divider",
                                                    flex: 1,
                                                    minWidth: "300px",
                                                }}
                                            >
                                                <Box sx={{ flex: 1 }}>
                                                    <Typography
                                                        variant="subtitle2"
                                                        sx={{
                                                            fontWeight: 500,
                                                            color: "text.primary",
                                                        }}
                                                    >
                                                        {props.entity.getCurrentEntityName()}
                                                    </Typography>
                                                    <Typography
                                                        variant="caption"
                                                        sx={{
                                                            color: "text.secondary",
                                                            display: "block",
                                                        }}
                                                    >
                                                        Version {versionId}
                                                        {version.description && ` - ${version.description}`}
                                                    </Typography>
                                                    {version.modifiedBy && (
                                                        <Typography
                                                            variant="caption"
                                                            sx={{
                                                                color: "text.secondary",
                                                                display: "block",
                                                            }}
                                                        >
                                                            Modified by: {version.modifiedBy}
                                                        </Typography>
                                                    )}
                                                </Box>
                                                <Typography
                                                    variant="body2"
                                                    color="text.secondary"
                                                    sx={{
                                                        ml: 2,
                                                        whiteSpace: "nowrap",
                                                    }}
                                                >
                                                    {new Date(version.creationTimeUtc).toLocaleString()}
                                                </Typography>
                                            </Box>
                                        </TimelineItem>
                                    );
                                })}
                            </Timeline>
                        </Box>
                    </RadioGroup>
                </FormControl>
            )}

            <Box
                mt={4}
                display="flex"
                justifyContent="flex-end"
                gap={2}
                sx={{
                    borderTop: "1px solid",
                    borderColor: "divider",
                    pt: 3,
                }}
            >
                <Button variant="outlined" onClick={onClose}>
                    Back
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSubmitSelectedVersion}
                    disabled={selectedVersionId === props.entity.getCurrentEntityVersionId()}
                >
                    {selectedVersionId === props.entity.getCurrentEntityVersionId()
                        ? "Current Version"
                        : "Rollback to version"}
                </Button>
            </Box>
        </Box>
    );
}

export { VersionTimeline };
