import { Box, Button, Checkbox, FormControlLabel, MenuItem, Select, Stack, TextField, Typography } from "@mui/material";
import React, { useState } from "react";

import { useAuth0 } from "@auth0/auth0-react";
import { REACT_APP_BACKEND_PROTECTED_URL } from "../../../../configs/config";
import { IPrompt } from "../../../../models/interfaces/prompt/IPrompt";
import { AgentFunctionRoleEnum } from "../../../../models/interfaces/workbench/agent/AgentFunctionRoleEnum";
import { IAction } from "../../../../models/interfaces/workbench/agent/IAction";
import { IAgent } from "../../../../models/interfaces/workbench/agent/IAgent";
import { IAgentPromptView } from "../../../../models/interfaces/workbench/agent/IAgentPromptView";
import { ISavePromptModalProp } from "../../../../models/interfaces/workbench/page_prop/ISavePromptModalProp";
import GetRequestHeader from "../../../../utils/auth_utils";
import { StringUtils } from "../../../../utils/string_utils";
import ActionEditBox from "./ActionEditBox";
import PropertiesEditBox from "./PropertiesEditBox";
import TagsEditBox from "./TagsEditBox";

const agentPromptUrl = `${REACT_APP_BACKEND_PROTECTED_URL}/agentPrompt`;
const createOptions = (body: string, authToken?: string) =>
    GetRequestHeader({
        method: "POST",
        body: body,
        authToken: authToken,
    });
const updateOptions = (body: string, authToken?: string) =>
    GetRequestHeader({
        method: "PUT",
        body: body,
        authToken: authToken,
    });

const SaveAgentView: React.FC<ISavePromptModalProp> = ({ props }: ISavePromptModalProp) => {
    const [selectedAction, setSelectedAction] = useState<{
        [key: string]: IAction;
    }>(props.agent?.agentActions ?? {});

    const { getAccessTokenSilently } = useAuth0();

    const handleSaveAgent = async (): Promise<void> => {
        try {
            const newPromptObj: IPrompt = {
                ...props.prompt,
                promptId: props.prompt.promptId ?? null,
                ownerId: props.userId,
            };

            const newAgentObj: IAgentPromptView = {
                agentId: props.agent?.agentId ?? "",
                agentName: props.agent?.agentName ?? "",
                agentFunctionRole: props.agent?.agentFunctionRole ?? AgentFunctionRoleEnum.Assistant,
                agentActions: selectedAction,
                llmEngineName: "OpenAI",
                prompt: newPromptObj,
                ownerId: props.userId,
                isPrivate: props.agent?.isPrivate ?? false,
            };
            console.log(`Saving agent ${JSON.stringify(newAgentObj)}`);
            const tarUrl = `${agentPromptUrl}${
                StringUtils.IsStringUndefinedNullOrEmpty(props.agent?.agentId) // Update or create agent?
                    ? ""
                    : `/${props.agent?.agentId}`
            }`;
            const authToken = await getAccessTokenSilently();
            const opt = StringUtils.IsStringUndefinedNullOrEmpty(props.agent?.agentId)
                ? createOptions(JSON.stringify(newAgentObj), authToken)
                : updateOptions(JSON.stringify(newAgentObj), authToken);
            const response = await fetch(tarUrl, opt);
            if (!response.ok) throw new Error("API request failed: " + response.statusText);
            const data = await response.json();
            console.log(`Agent saved successfully ${JSON.stringify(data)} `);

            if (data) {
                const agentDef: IAgent = {
                    agentId: data.agentId,
                    agentName: data.agentName,
                    agentFunctionRole: data.agentFunctionRole,
                    agentActions: data.agentActions,
                    promptId: data.prompt.promptId,
                    llmEngineName: data.llmEngineName,
                    isPrivate: data.isPrivate,
                    ownerId: data.ownerId,
                    versionId: data.versionId,
                    creationTimeUtc: data.creationTimeUtc,
                    creationTimeUtcReadable: data.creationTimeUtcReadable,
                };
                props.setAgent(agentDef);
                setSelectedAction(agentDef.agentActions);

                props.setSelectedAgents((prev) => {
                    const next = { ...prev };
                    next[agentDef.agentId] = agentDef;
                    return next;
                });

                props.setPrompt((data as unknown as IAgentPromptView).prompt as IPrompt);
            }
            props.setIsSavePromptModalOpen(false);
        } catch (error) {
            console.error("An error occurs", error);
        }
    };

    return (
        <Box id="modal-description">
            {/* Modal Content */}
            <Box display="flex" height={700}>
                {/* Left Half Area */}
                <Box flex="1" mr={2} overflow="auto" pr={2} borderRight="1px solid gray">
                    <Box flex="1" overflow="auto">
                        <Typography variant="subtitle2" sx={{ mb: 1 }}>
                            Owner Information
                        </Typography>
                        <TextField
                            label="Owner ID"
                            variant="filled"
                            value={props.userId}
                            disabled
                            fullWidth
                            sx={{ mb: 1 }}
                        />
                        <TextField label="Owner Name" variant="filled" value={props.userName} disabled fullWidth />
                    </Box>
                    <Box flex="1" overflow="auto" mt={2}>
                        <Typography variant="subtitle2" sx={{ mb: 1 }}>
                            Agent Information
                        </Typography>
                        <Box flex="1" overflow="auto">
                            <Typography variant="subtitle2" sx={{ mb: 1 }}>
                                Agent Function Role
                            </Typography>
                            <Select
                                fullWidth
                                value={props.agent?.agentFunctionRole || AgentFunctionRoleEnum.Assistant}
                                onChange={(e) => {
                                    props.setAgent((prev) => {
                                        const newVal = AgentFunctionRoleEnum[e.target.value as AgentFunctionRoleEnum];
                                        if (prev === undefined) {
                                            return {
                                                agentFunctionRole: newVal,
                                            } as IAgent;
                                        }

                                        return {
                                            ...prev,
                                            agentFunctionRole: newVal,
                                        };
                                    });
                                }}
                            >
                                {Object.values(AgentFunctionRoleEnum).map((role) => (
                                    <MenuItem
                                        key={role}
                                        value={role}
                                        selected={role === props.agent?.agentFunctionRole}
                                    >
                                        {role}
                                    </MenuItem>
                                ))}
                            </Select>
                        </Box>
                        <Box flex="1" overflow="auto" mt={2}>
                            <TextField
                                fullWidth
                                label="Agent Name"
                                variant="filled"
                                placeholder="Give your agent a name"
                                required
                                onChange={(e) => {
                                    props.setAgent((prev) => {
                                        if (prev === undefined) {
                                            return {
                                                agentName: e.target.value,
                                            } as IAgent;
                                        }

                                        return {
                                            ...prev,
                                            agentName: e.target.value,
                                        };
                                    });
                                    props.setPrompt((prev) => {
                                        return {
                                            ...prev,
                                            promptName: e.target.value,
                                        };
                                    });
                                }}
                                value={props.agent?.agentName}
                            />
                        </Box>
                        <Box flex="1" overflow="auto" mt={2}>
                            <TextField
                                fullWidth
                                label="Agent ID"
                                variant="filled"
                                value={props.agent?.agentId || "Not saved yet"}
                                disabled
                            />
                        </Box>
                    </Box>
                    <Box flex="1" overflow="auto" mt={2}>
                        <Typography variant="subtitle2" sx={{ mb: 1 }}>
                            Agent Prompt Content
                        </Typography>
                        <Stack direction="row">
                            <Box
                                sx={{
                                    whiteSpace: "pre-wrap",
                                    overflowX: "clip",
                                }}
                            >
                                {StringUtils.GetHtmlFreePromptContent(props.prompt.promptContent) || ""}
                            </Box>
                        </Stack>
                    </Box>
                </Box>
                {/* Right Half Area */}
                <Box flex="1" ml={2} overflow="auto" display="flex" flexDirection="column">
                    <Box flex="1">
                        <Typography variant="subtitle2" sx={{ mb: 1 }}>
                            Description
                        </Typography>
                        <TextField
                            id="prompt-description"
                            label="Agent Description"
                            variant="filled"
                            placeholder="Describe your agent"
                            onChange={(e) =>
                                props.setPrompt((prev) => {
                                    return {
                                        ...prev,
                                        promptDescription: e.target.value,
                                    };
                                })
                            }
                            value={props.prompt.promptDescription}
                            multiline
                            rows={4}
                            fullWidth
                        />
                    </Box>
                    {/* Action Section */}
                    <Box flex="1" mt={2}>
                        <Typography variant="subtitle2" sx={{ mb: 1 }}>
                            Skills
                        </Typography>
                        <ActionEditBox
                            userId={props.userId}
                            isSavePromptModalOpen={props.isSavePromptModalOpen}
                            setIsSavePromptModalOpen={props.setIsSavePromptModalOpen}
                            selectedAction={selectedAction}
                            setSelectedAction={setSelectedAction}
                            agentActions={props.agent?.agentActions ?? {}}
                        />
                    </Box>
                    {/* Properties Section */}
                    <Box flex="1" mt={2}>
                        <PropertiesEditBox
                            properties={props.prompt.properties}
                            propertiesChange={(newProperties) => {
                                props.setPrompt((prev) => {
                                    return {
                                        ...prev,
                                        properties: newProperties,
                                    };
                                });
                            }}
                        />
                    </Box>
                    {/* Tags Section */}
                    <Box flex="1" mt={2}>
                        <Typography variant="subtitle2" sx={{ mb: 1 }}>
                            Tags
                        </Typography>
                        <TagsEditBox
                            tags={props.prompt.tags}
                            tagsChange={(newTags) => {
                                props.setPrompt((prev) => {
                                    return { ...prev, tags: newTags };
                                });
                            }}
                        />
                    </Box>
                    <Box flex="1" mt={2}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={props.agent?.isPrivate || false}
                                    onChange={(e) => {
                                        props.setAgent(
                                            (prev) =>
                                                ({
                                                    ...prev,
                                                    isPrivate: e.target.checked,
                                                } as IAgent)
                                        );
                                    }}
                                />
                            }
                            label="Make this agent private"
                        />
                    </Box>
                </Box>
            </Box>
            {/* Buttom Buttons Area */}
            <Box mt={2} display="flex" justifyContent="flex-end">
                <Box padding={2}>
                    <Button variant="contained" color="warning" onClick={() => props.setIsSavePromptModalOpen(false)}>
                        Cancel
                    </Button>
                </Box>
                <Box padding={2}>
                    <Button variant="contained" color="secondary" onClick={handleSaveAgent}>
                        Save Agent Prompt
                    </Button>
                </Box>
            </Box>
        </Box>
    );
};
export default SaveAgentView;
