import { SxProps, Theme } from "@mui/material";

export const modalBoxStyle: SxProps<Theme> = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "50%",
    backgroundColor: "#1e1e1e", // Dark background
    border: "1px solid #333",
    boxShadow: "0 8px 32px rgba(0, 0, 0, 0.35)",
    padding: 4,
    borderRadius: "8px",
    color: "#ffffff", // White text
    "& .MuiTextField-root": {
        backgroundColor: "#2d2d2d", // Slightly lighter dark for text fields
    },
    "& .MuiInputBase-input": {
        color: "#ffffff", // White text in input fields
    },
    "& .MuiInputLabel-root": {
        color: "#999", // Gray labels
    },
    "& .MuiTypography-root": {
        color: "#ffffff", // White text for Typography components
    },
};
