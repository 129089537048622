import { useAuth0 } from "@auth0/auth0-react";
import { Box, Container, Grid, Typography } from "@mui/material";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import { Socket } from "socket.io-client";
import { GetAgentDefinitionById } from "../../components/agent/ManageAgent";
import { sendMessage, startNewConversation } from "../../components/chat/ManageConversation";
import Chatbox from "../../components/chat/chat_box/Chatbox";
import { loadPrompt } from "../../components/prompt/ManagePrompt";
import { GetSolutionById } from "../../components/solution/ManageSolution";
import { OpenAIDefaultLLMSettings } from "../../configs/OpenAIDefaultLLMSettings";
import { SocketContext } from "../../context/SocketContext";
import { ISolution } from "../../models/interfaces/solution/ISolution";
import { IAgent } from "../../models/interfaces/workbench/agent/IAgent";
import { IAgentInstance } from "../../models/interfaces/workbench/agent/IAgentInstance";
import { OpenAIAgentConstants } from "../../models/interfaces/workbench/agent/OpenAIAgentConstants";
import { ChatSessionStatusEnum } from "../../models/interfaces/workbench/chat/ChatSessionStatusEnum";
import { IConversation } from "../../models/interfaces/workbench/chat/IChatSession";
import { ChatInitStateEnum } from "../../types/ChatInitStateEnum";
import { StringUtils } from "../../utils/string_utils";
import { componentGroupStyle, containerStyle, groupHeaderStyle, titleStyle } from "./WorkbenchStyles";

const DefaultSysPrompt = "Help user achieve their goal by recruiting agents and work out a solution.";

const Chat: React.FC = () => {
    const { user, getAccessTokenSilently } = useAuth0();
    const { socket } = useContext(SocketContext);
    const [searchParams] = useSearchParams();
    const [isInitializing, setIsInitializing] = useState(ChatInitStateEnum.Initializing);

    const location = useLocation();
    const EmptyConversation: IConversation = {
        chatSessionId: "",
        ownerId: "",
        messageHistory: [],
        chatSessionStatus: ChatSessionStatusEnum.Active,
        llmSettings: OpenAIDefaultLLMSettings,
        participantProfiles: [],
    };
    const userSearchQuery = location.state?.searchResult?.search_term as string;
    const solutionId = searchParams.get("solutionId") || (location.state?.searchResult.solutionId as string);
    const [systemPrompt, setSystemPrompt] = useState<string>(
        (location.state?.searchResult.solutionSystemPrompt as string) ?? DefaultSysPrompt
    );

    // Handle data passed in from previous page
    const [currentSolution, setCurrentSolution] = useState<ISolution>();

    // Chat session state
    const [conversation, setConversation] = useState<IConversation>(EmptyConversation);

    // Active agent instances
    const [activeAgentInstances, setActiveAgentInstances] = useState<IAgentInstance[]>([]);

    // Keep agent prompt def cached {agentId: agent def}
    const [selectedAgents, setSelectedAgents] = useState<{
        [key: string]: IAgent;
    }>({});

    // Reference to the last message in chat box
    const messagesEndRef = useRef<HTMLDivElement>(null);

    // Set initializing to false when conversation is ready
    useEffect(() => {
        if (conversation?.chatSessionId && activeAgentInstances.length > 0) {
            setIsInitializing(ChatInitStateEnum.Active);
        }
    }, [conversation?.chatSessionId, activeAgentInstances.length]);

    useEffect(() => {
        if (currentSolution === undefined) return;

        const loadSolutionData = async () => {
            const authToken = await getAccessTokenSilently();
            if (authToken === undefined || authToken === null || authToken === "") {
                throw new Error("Auth token is invalid");
            }

            // Load agent definitions
            const agentDefs = await Promise.all(
                Object.entries(currentSolution.agents).map(async ([agentId, versionId]) => {
                    const agentDef = await GetAgentDefinitionById(agentId, versionId, authToken);
                    return [agentId, agentDef];
                })
            );
            setSelectedAgents(Object.fromEntries(agentDefs));

            // Load system prompt
            const promptId = Object.keys(currentSolution.solutionSystemPrompt)[0];
            const versionId = currentSolution.solutionSystemPrompt[promptId];
            const promptContent = await loadPrompt(promptId, versionId, authToken);
            setSystemPrompt(promptContent.promptContent);
        };

        loadSolutionData().catch(console.error);
    }, [currentSolution, getAccessTokenSilently]);

    const sendMessageAsync = async ({
        socket,
        chatSessionId,
        userId,
        userName,
        userInput,
        recipientId,
    }: {
        socket: Socket;
        userId: string;
        chatSessionId: string;
        userName: string;
        userInput: string;
        recipientId?: string;
    }) => {
        if (StringUtils.IsStringUndefinedNullOrEmpty(chatSessionId)) return;
        if (StringUtils.IsStringUndefinedNullOrEmpty(userInput)) return;
        const msg = await sendMessage({
            socket: socket,
            chatSessionId: chatSessionId,
            userId: userId,
            userName: userName,
            userInputText: userInput,
            sendTo: recipientId ?? OpenAIAgentConstants.DefaultButlerId,
        });
        return msg;
    };

    const fetchNewConversationAsync = async (
        agentList: { [agentId: string]: number },
        systemPrompt: string
    ): Promise<IConversation> => {
        console.log(`Starting conversation`);
        const isButlerRequest = currentSolution === undefined ? true : false;
        const returnConversation: IConversation = await startNewConversation({
            socket: socket,
            systemPrompt: systemPrompt,
            userId: user?.sub ?? "",
            agentList: agentList,
            isButlerRequest: isButlerRequest,
        });
        return returnConversation;
    };

    const startConversationAsync = async (
        agentList: { [agentId: string]: number },
        systemPrompt: string
    ): Promise<void> => {
        const returnConversation = await fetchNewConversationAsync(agentList, systemPrompt);
        setConversation(returnConversation);
    };

    useEffect(() => {
        if (socket === undefined || socket.connected === false) {
            console.debug("Socket is not connected");
            return;
        }
        const initChat = async () => {
            let newConv: IConversation;

            if (!StringUtils.IsStringUndefinedNullOrEmpty(solutionId)) {
                const authToken = await getAccessTokenSilently();
                if (authToken === undefined || authToken === null || authToken === "")
                    throw new Error("Auth token is invalid");
                const sol = await GetSolutionById(solutionId, authToken);
                if (sol === undefined || sol === null) throw new Error("Solution is undefined");
                setCurrentSolution(sol);

                const promptId = Object.keys(sol.solutionSystemPrompt)[0];
                const versionId = sol.solutionSystemPrompt[promptId];
                const promptContent = await loadPrompt(promptId, versionId, authToken);
                setSystemPrompt(promptContent.promptContent);

                console.log(`Starting conversation on page load with solution ${sol?.solutionName}`);
                newConv = await fetchNewConversationAsync(sol?.agents ?? {}, promptContent.promptContent);
            } else {
                console.log(`Starting conversation on page load`);
                newConv = await fetchNewConversationAsync(currentSolution?.agents ?? {}, systemPrompt);
            }
            newConv.participantProfiles.sort((a, b) => {
                if (
                    a.participantName?.toLowerCase().includes("assistant") ||
                    a.participantName?.toLowerCase().includes("manager") ||
                    a.participantName?.toLowerCase().includes("planner")
                ) {
                    return -1;
                }
                if (
                    b.participantName?.toLowerCase().includes("assistant") ||
                    b.participantName?.toLowerCase().includes("manager") ||
                    b.participantName?.toLowerCase().includes("planner")
                ) {
                    return 1;
                }
                return a.participantName < b.participantName ? -1 : 1;
            });
            setConversation(newConv);
        };

        initChat().catch((err) => {
            console.error(err);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [socket, socket?.connected, solutionId]);

    useEffect(() => {
        if (socket === undefined || socket.connected === false) {
            console.debug("Socket is not connected");
            return;
        }
        const initUserMsg = async () => {
            // if (sendMsgCalled.current === true) return;
            if (StringUtils.IsStringUndefinedNullOrEmpty(conversation?.chatSessionId)) return;

            console.log("Asking user question on page load");
            let recp = OpenAIAgentConstants.DefaultButlerId;
            if (conversation?.participantProfiles !== undefined && conversation.participantProfiles.length > 0) {
                recp = conversation.participantProfiles[0].participantId;
            }

            await sendMessageAsync({
                socket: socket,
                chatSessionId: conversation.chatSessionId,
                userId: user?.sub ?? "",
                userName: user?.nickname ?? "",
                userInput: userSearchQuery,
                recipientId: recp,
            });

            // sendMsgCalled.current = true; // Make sure this is called only once
        };
        initUserMsg().catch((err) => {
            console.error(err);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [conversation.chatSessionId, socket]);

    return (
        <Container
            sx={{
                ...containerStyle,
                maxWidth: "100% !important", // Override MUI's default maxWidth
                height: "calc(100vh - 64px)", // Full viewport height minus typical AppBar height
                p: 2, // Reduce padding to maximize space
            }}
        >
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Box
                        sx={{
                            ...componentGroupStyle,
                            height: "calc(100vh - 96px)", // Full height minus container padding and AppBar
                            display: "flex",
                            flexDirection: "column",
                        }}
                    >
                        <Box sx={groupHeaderStyle}>
                            <Typography sx={titleStyle}>
                                {`Talking to [${currentSolution?.solutionName || "Muggle AI Assistant"}]`}
                            </Typography>
                        </Box>
                        <Box sx={{ flexGrow: 1, overflow: "hidden" }}>
                            <Chatbox
                                socket={socket}
                                userId={user?.sub ?? ""}
                                userName={user?.nickname ?? ""}
                                conversation={conversation}
                                setConversation={setConversation}
                                systemPrompt={systemPrompt}
                                startNewConversationAsync={(agentList) =>
                                    startConversationAsync(agentList, systemPrompt)
                                }
                                selectedAgents={selectedAgents}
                                setSelectedAgents={setSelectedAgents}
                                activeAgentInstances={activeAgentInstances}
                                setActiveAgentInstances={setActiveAgentInstances}
                                messagesEndRef={messagesEndRef}
                                isInitializing={isInitializing}
                                setIsInitializing={setIsInitializing}
                            />
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </Container>
    );
};

export default Chat;
