import {
    Box,
    Button,
    Checkbox,
    FormControlLabel,
    List,
    ListItem,
    ListItemText,
    Stack,
    TextField,
    Typography,
} from "@mui/material";
import React from "react";

import { useAuth0 } from "@auth0/auth0-react";
import { REACT_APP_BACKEND_PROTECTED_URL } from "../../../../configs/config";
import { IPrompt } from "../../../../models/interfaces/prompt/IPrompt";
import { PromptTypeEnum } from "../../../../models/interfaces/prompt/PromptTypeEnum";
import { ISolution } from "../../../../models/interfaces/solution/ISolution";
import { ISolutionPromptView } from "../../../../models/interfaces/solution/ISolutionPromptView";
import { ILLMMessage } from "../../../../models/interfaces/workbench/chat/ILLMMessage";
import { IMessage } from "../../../../models/interfaces/workbench/chat/IMessage";
import { ISavePromptModalProp } from "../../../../models/interfaces/workbench/page_prop/ISavePromptModalProp";
import GetRequestHeader from "../../../../utils/auth_utils";
import { StringUtils } from "../../../../utils/string_utils";
import PropertiesEditBox from "./PropertiesEditBox";
import TagsEditBox from "./TagsEditBox";

const SAVE_SOLUTION_URL = `${REACT_APP_BACKEND_PROTECTED_URL}/solutionPrompt`;
const POST_SOLUTION_OPTIONS = (solutionObj: ISolutionPromptView, authToken?: string) =>
    GetRequestHeader({
        method: "POST",
        authToken: authToken,
        body: JSON.stringify(solutionObj),
    });

const SaveSolutionView: React.FC<ISavePromptModalProp> = ({ props }: ISavePromptModalProp) => {
    const { getAccessTokenSilently } = useAuth0();

    const handleSaveSolution = async (): Promise<void> => {
        try {
            if (props.solution === undefined || Object.keys(props.solution).length === 0) return;

            const agentList = Object.fromEntries(
                Object.values(props.selectedAgents).map((agent) => [agent.agentId, agent.versionId])
            );

            const promptObj: IPrompt = {
                ...props.prompt,
                ownerId: props.userId,
                promptType: PromptTypeEnum.Solution,
                solutionId: props.solution.solutionId || "",
                promptDescription: props.solution.solutionDescription || "",
                promptName: props.solution.solutionName || "",
                properties: props.solution.properties || {},
                tags: props.solution.tags || [],
            };

            const newSolutionObj: ISolutionPromptView = {
                ...props.solution,
                ownerId: props.userId,
                agents: agentList,
                solutionSystemPrompt: promptObj,
                firstMsg: props.solution.firstMsg,
            };

            console.log(`Saving solution ${JSON.stringify(newSolutionObj)}`);

            const response = await fetch(
                SAVE_SOLUTION_URL,
                POST_SOLUTION_OPTIONS(newSolutionObj, await getAccessTokenSilently())
            );
            const data: ISolutionPromptView = await response.json();
            if (response.ok === false) throw new Error("API request failed: " + response.statusText);
            const retSol: ISolution = {
                solutionId: data.solutionId,
                solutionName: data.solutionName,
                solutionDescription: data.solutionDescription,
                properties: data.properties,
                tags: data.tags,
                ownerId: data.ownerId,
                solutionSystemPrompt: {
                    [data.solutionSystemPrompt.promptId]: data.solutionSystemPrompt.versionId,
                },
                firstMsg: data.firstMsg,
                agents: data.agents ?? {},
                isActive: data.isActive,
                isPrivate: data.isPrivate,
                versionId: data.versionId,
                creationTimeUtc: data.creationTimeUtc,
                creationTimeUtcReadable: data.creationTimeUtcReadable,
            };

            props.setPrompt(data.solutionSystemPrompt);
            props.setSolution(retSol);
            console.log(`Solution saved successfully ${JSON.stringify(data)} `);

            props.setIsSavePromptModalOpen(false);
        } catch (error) {
            console.error("An error occurs", error);
        }
    };

    return (
        <Box id="modal-description">
            {/* Modal Content */}
            <Box display="flex" height={700}>
                {/* Left Half Area */}
                <Box flex="1" mr={2} overflow="auto" pr={2} borderRight="1px solid gray">
                    <Box flex="1" overflow="auto">
                        <Typography variant="subtitle2" sx={{ mb: 1 }}>
                            Owner Information
                        </Typography>
                        <TextField
                            label="Owner ID"
                            variant="filled"
                            value={props.userId}
                            disabled
                            fullWidth
                            sx={{ mb: 1 }}
                        />
                        <TextField label="Owner Name" variant="filled" value={props.userName} disabled fullWidth />
                    </Box>
                    <Box flex="1" overflow="auto" mt={2}>
                        <Typography variant="subtitle2" sx={{ mb: 1 }}>
                            Solution Information
                        </Typography>
                        <TextField
                            label="Solution Name"
                            variant="filled"
                            placeholder="Give your solution a name"
                            required
                            fullWidth
                            onChange={(e) =>
                                props.setSolution((prev) => {
                                    const ret = {
                                        ...prev,
                                        solutionName: e.target.value,
                                    } as ISolution;
                                    return ret;
                                })
                            }
                            value={props.solution?.solutionName}
                        />
                    </Box>
                    <Box flex="1" overflow="auto" mt={2}>
                        <TextField
                            label="Solution ID"
                            variant="filled"
                            value={props.solution?.solutionId || "Not saved yet"}
                            disabled
                            fullWidth
                        />
                    </Box>
                    <Box flex="1" overflow="auto" mt={2}>
                        <Typography variant="subtitle2" sx={{ mb: 1 }}>
                            Solution Prompt Content
                        </Typography>
                        <Stack direction="row">
                            <Box
                                sx={{
                                    whiteSpace: "pre-wrap",
                                    overflowX: "clip",
                                }}
                            >
                                {StringUtils.GetHtmlFreePromptContent(props.prompt.promptContent) || ""}
                            </Box>
                        </Stack>
                    </Box>
                    <Box flex="1" overflow="auto" mt={2}>
                        <Typography variant="subtitle2" sx={{ mb: 1 }}>
                            Selected Agents
                        </Typography>
                        <List dense={true}>
                            {Object.values(props.selectedAgents).map((agent) => {
                                return (
                                    <ListItem>
                                        <ListItemText primary={`${agent.agentName}: version(${agent.versionId})`} />
                                    </ListItem>
                                );
                            })}
                        </List>
                    </Box>
                    <Box flex="1" overflow="auto" mt={2}>
                        <Typography variant="subtitle2" sx={{ mb: 1 }}>
                            First Message
                        </Typography>
                        <TextField
                            id="solution-first-msg"
                            label="First Message"
                            variant="outlined"
                            placeholder="Write the first message to send to the group chat after the system prompt here..."
                            onChange={(e) =>
                                props.setSolution((prev) => {
                                    const ret = {
                                        ...prev,
                                        firstMsg: {
                                            llmMessage: {
                                                role: "user",
                                                content: e.target.value,
                                                name: props.userName,
                                            } as ILLMMessage,
                                            fromUserId: props.userId,
                                        } as IMessage,
                                    } as ISolution;
                                    return ret;
                                })
                            }
                            value={props.solution?.firstMsg?.llmMessage.content}
                            multiline
                            fullWidth
                        />
                    </Box>
                </Box>
                {/* Right Half Area */}
                <Box flex="1" ml={2} overflow="auto" display="flex" flexDirection="column">
                    <Box flex="1">
                        <Typography variant="subtitle2" sx={{ mb: 1 }}>
                            Description
                        </Typography>
                        <TextField
                            id="solution-description"
                            label="Solution Description"
                            variant="filled"
                            placeholder="Describe your solution"
                            onChange={(e) =>
                                props.setSolution((prev) => {
                                    const ret = {
                                        ...prev,
                                        solutionDescription: e.target.value,
                                    } as ISolution;
                                    return ret;
                                })
                            }
                            value={props.solution?.solutionDescription}
                            multiline
                            rows={4}
                            fullWidth
                        />
                    </Box>
                    {/* Properties Section */}
                    <Box flex="1" mt={2}>
                        <PropertiesEditBox
                            properties={props.solution?.properties || {}}
                            propertiesChange={(newProperties) => {
                                props.setSolution((prev) => {
                                    const ret = {
                                        ...prev,
                                        properties: newProperties,
                                    } as ISolution;
                                    return ret;
                                });
                            }}
                        />
                    </Box>
                    {/* Tags Section */}
                    <Box flex="1" mt={2}>
                        <Typography variant="subtitle2" sx={{ mb: 1 }}>
                            Tags
                        </Typography>
                        <TagsEditBox
                            tags={props.solution?.tags || []}
                            tagsChange={(newTags) => {
                                props.setSolution((prev) => {
                                    return {
                                        ...prev,
                                        tags: newTags,
                                    } as ISolution;
                                });
                            }}
                        />
                    </Box>
                    <Box flex="1" mt={2}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={props.solution?.isPrivate || false}
                                    onChange={(e) => {
                                        props.setSolution(
                                            (prev) =>
                                                ({
                                                    ...prev,
                                                    isPrivate: e.target.checked,
                                                } as ISolution)
                                        );
                                    }}
                                />
                            }
                            label="Make this solution private"
                        />
                    </Box>
                </Box>
            </Box>
            {/* Buttom Buttons Area */}
            <Box mt={2} display="flex" justifyContent="flex-end">
                <Box padding={2}>
                    <Button variant="contained" color="warning" onClick={() => props.setIsSavePromptModalOpen(false)}>
                        Cancel
                    </Button>
                </Box>
                <Box padding={2}>
                    <Button variant="contained" color="secondary" onClick={handleSaveSolution}>
                        Save Solution
                    </Button>
                </Box>
            </Box>
        </Box>
    );
};

export default SaveSolutionView;
