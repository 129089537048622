import { type SxProps, type Theme } from "@mui/system";

export const appBarStyle: SxProps<Theme> = {
  backgroundColor: "#19191a",
  color: "#fff",
};

export const ButtonboxStyle: SxProps<Theme> = {
  display: "flex",
  flexGrow: 1,
  alignItems: "center",
};

export const buttonStyle: SxProps<Theme> = {
  marginRight: "15px",
  fontFamily: "Montserrat",
  fontSize: "18px",
  "&:hover": {
    color: "primary.main",
  },
};

export const topSearchBoxStyle: SxProps<Theme> = {
  marginRight: "20px",
};

export const autocompleteStyle: SxProps<Theme> = {
  width: "250px",
  "& .MuiOutlinedInput-input": {
    padding: "10px 14px",
  },
  "& .MuiOutlinedInput-root": {
    "&.Mui-focused fieldset": {
      borderWidth: "1px",
    },
    "& fieldset": {
      borderWidth: "1px",
    },
  },
  '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]': {
    padding: 0,
    "& .MuiAutocomplete-input:first-of-type": {
      paddingLeft: "14px",
    },
    "& .MuiAutocomplete-endAdornment": {
      right: "14px",
    },
  },
};

export const textFieldStyle: SxProps<Theme> = {
  variant: "outlined",
  placeholder: "Search...",
  size: "small",
  backgroundColor: "#fff",
  "& .MuiOutlinedInput-input": {
    padding: "10px 14px",
  },
  "& .MuiOutlinedInput-root": {
    "&.Mui-focused fieldset": {
      borderWidth: "1px",
    },
    "& fieldset": {
      borderWidth: "1px",
    },
  },
};

export const muneBoxStyle: SxProps<Theme> = {
  display: "flex",
  marginLeft: "auto",
  alignItems: "center",
};

export const arrowDropDownIconStyle: SxProps<Theme> = {
  backgroundColor: "#fff",
};
