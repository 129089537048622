// src/context/SocketContext.js
import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from "react";
import { Socket, io } from "socket.io-client";
import { REACT_APP_BACKEND_SOCKET_URL } from "../configs/config";
import * as SocketEvent from "../models/interfaces/events/socket_events";
import AuthPayload from "../types/socket/auth_payload";
import ClientType from "../types/socket/enum_client_type";
import { StringUtils } from "../utils/string_utils";
import { SocketContext } from "./SocketContext";
const SocketProvider = ({ children }: { children: React.ReactNode }) => {
    const { isLoading, isAuthenticated, user, getAccessTokenSilently } = useAuth0();
    const [socket, setSocket] = useState<Socket>();
    const [loading, setLoading] = useState<boolean>(true);

    function setupSocketState(conn: Socket | undefined) {
        if (conn === undefined) return;
        function onConnect(): void {
            if (!conn?.connected) return;
            if (!StringUtils.IsStringUndefinedNullOrEmpty((conn.auth as { authToken: string }).authToken)) {
                console.log(`Connected to socket.io server with auth.`);
            } else {
                console.log(`Connected to socket.io server.`);
            }
            setLoading(false);
        }

        function onDisconnect(): void {
            if (conn?.connected) return;
            console.log(`Disconnected from socket.io server.`);
        }

        function onConnectError(err: Error): void {
            console.error(`connect_error due to ${JSON.stringify(err)}, ${err.message}`);
            console.error(Object.getOwnPropertyDescriptors(err));
        }

        function onReconnect(): void {
            console.log(`Reconnected to socket.io server.`);
            setLoading(false);
        }

        function onReconnectAttempt(attempt: number): void {
            console.log(`Reconnect attempt: ${attempt}.`);
        }

        conn.on(SocketEvent.CONNECT, onConnect);
        conn.on(SocketEvent.DISCONNECT, onDisconnect);
        conn.on(SocketEvent.CONNECT_ERROR, onConnectError);
        conn.io.on(SocketEvent.RECONNECT, onReconnect);
        conn.io.on(SocketEvent.RECONNECT_ATTEMPT, onReconnectAttempt);

        if (!conn.connected) conn.connect();

        return () => {
            conn.off(SocketEvent.CONNECT, onConnect);
            conn.off(SocketEvent.DISCONNECT, onDisconnect);
            conn.off(SocketEvent.CONNECT_ERROR, onConnectError);
            conn.off(SocketEvent.RECONNECT, onReconnect);
            conn.off(SocketEvent.RECONNECT_ATTEMPT, onReconnectAttempt);
        };
    }

    useEffect(() => {
        if (isLoading || !isAuthenticated || !user) return;

        (async () => {
            setLoading(true);
            try {
                if (socket !== undefined && socket.connected) socket.disconnect();

                const token = await getAccessTokenSilently();
                const authPayload: AuthPayload = {
                    userId: user!.sub!,
                    nickname: user!.nickname!,
                    email: user!.email!,
                    chatSessionId: "",
                    accessToken: token,
                    clientType: ClientType.WEB,
                };
                let newConn = io(REACT_APP_BACKEND_SOCKET_URL, {
                    auth: authPayload,
                    autoConnect: true,
                    extraHeaders: {
                        Authorization: `Bearer ${token}`,
                    },
                    reconnection: true,
                    reconnectionAttempts: 60,
                    reconnectionDelay: 10000,
                    reconnectionDelayMax: 60000,
                });
                setupSocketState(newConn);
                setSocket(newConn);
            } catch (error) {
                console.error(error);
            }
        })();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoading, getAccessTokenSilently, isAuthenticated, user]);

    return (
        <SocketContext.Provider value={{ socket: socket, loading: loading, setLoading: setLoading }}>
            {children}
        </SocketContext.Provider>
    );
};

export default SocketProvider;
