// The MUI version of ./Chatbox.module.css
import { SxProps, Theme } from "@mui/material";

const inputTextFieldStyle: SxProps<Theme> = {
    "& .MuiOutlinedInput-root": {
        bgcolor: "#2C2C2C",
        color: "#E0E0E0",
        fontSize: "1rem",
        minHeight: "49px",
        borderRadius: 1,
        transition: "all 0.2s ease-in-out",
        "& fieldset": {
            borderColor: "#404040",
            borderWidth: 1,
        },
        "&:hover fieldset": {
            borderColor: "#666666",
        },
        "&.Mui-focused fieldset": {
            borderColor: "#1976d2",
            borderWidth: 2,
        },
        "& textarea": {
            lineHeight: "25px",
            padding: "12px 14px",
            minHeight: "25px !important",
            "&::-webkit-scrollbar": {
                width: "8px",
                height: "8px",
            },
            "&::-webkit-scrollbar-track": {
                bgcolor: "#1E1E1E",
            },
            "&::-webkit-scrollbar-thumb": {
                bgcolor: "#404040",
                borderRadius: "4px",
                "&:hover": {
                    bgcolor: "#666666",
                },
            },
        },
        "&.MuiInputBase-multiline": {
            padding: 0,
        },
    },
    "& .MuiInputLabel-root": {
        color: "#B0B0B0",
        transform: "translate(14px, 14px)",
        "&.Mui-focused, &.MuiFormLabel-filled": {
            color: "#1976d2",
            transform: "translate(14px, -9px) scale(0.75)",
        },
    },
    "& .MuiInputBase-input": {
        padding: "12px 14px",
        "&::placeholder": {
            color: "#808080",
            opacity: 1,
        },
    },
    "& .MuiSelect-select": {
        height: "49px",
        minHeight: "49px !important",
        display: "flex !important",
        alignItems: "center !important",
        padding: "0 14px !important",
    },
    "& .MuiMenuItem-root": {
        fontSize: "1rem",
        minHeight: "40px",
        display: "flex",
        alignItems: "center",
    },
};

const selectFieldStyle: SxProps<Theme> = {
    "& .MuiOutlinedInput-root": {
        bgcolor: "#2C2C2C",
        color: "#E0E0E0",
        fontSize: "1rem",
        height: "49px",
        borderRadius: 1,
        transition: "all 0.2s ease-in-out",
        "& fieldset": {
            borderColor: "#404040",
            borderWidth: 1,
        },
        "&:hover fieldset": {
            borderColor: "#666666",
        },
        "&.Mui-focused fieldset": {
            borderColor: "#1976d2",
            borderWidth: 2,
        },
    },
    "& .MuiInputLabel-root": {
        color: "#B0B0B0",
        transform: "translate(14px, 14px)",
        "&.Mui-focused, &.MuiFormLabel-filled": {
            color: "#1976d2",
            transform: "translate(14px, -9px) scale(0.75)",
        },
    },
    "& .MuiInputBase-input": {
        padding: "12px 14px",
        "&::placeholder": {
            color: "#808080",
            opacity: 1,
        },
    },
    "& .MuiSelect-select": {
        height: "49px",
        minHeight: "49px !important",
        display: "flex !important",
        alignItems: "center !important",
        padding: "0 14px !important",
    },
    "& .MuiMenuItem-root": {
        fontSize: "1rem",
        minHeight: "40px",
        display: "flex",
        alignItems: "center",
    },
};
const inputButtonStyle: SxProps<Theme> = {
    height: "49px",
    fontSize: "1rem",
};
// maxWidth: '30px', maxHeight: '30px', minWidth: '30px', minHeight: '30px'
export { inputButtonStyle, inputTextFieldStyle, selectFieldStyle };

