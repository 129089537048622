import { Autocomplete, CircularProgress, TextField } from "@mui/material";
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";
import axios from "axios";
import _ from "lodash";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
    REACT_APP_API_KEY,
    REACT_APP_BACKEND_PROMPT_INDEX_URL,
    REACT_APP_BACKEND_SENTENCE_INDEX_URL,
    REACT_APP_BACKEND_SOLUTION_INDEX_URL,
} from "../../configs/config";
import { ISearchOptionData } from "../../types/ISearchOptionData";
import { LoadEntityTypeEnum } from "../../types/entity/LoadEntityEnum";
import { IPromptSearchResult } from "../../types/prompt/IPromptSearchResult";
import { ISentence } from "../../types/prompt/ISentence";
import { ISolutionSearchResult } from "../../types/solution/ISolutionSearchResult";
import { StringUtils } from "../../utils/string_utils";
import styles from "./SearchInput.module.css";
import { SearchOptionTypeEnum } from "./SearchOptionTypeEnum";

const staticOptions = {
    target_model: "Target Model",
    interaction_type: "Interaction Type",
    shot_type: "Shot Type",
    role_type: "Role Type",
    domain_type: "Domain Type",
    complexity_level: "Complexity Level",
    task_type: "Task Type",
    sector: "Sector",
};

const MAX_RESULTS = 3;
const MAX_SEARCH_DISPLAY_CHARACTER = 125;
const SEARCH_BAR_PLACEHOLDER =
    'Describe what you want to achieve. Example: "I want you to keep an eye out for IPhone deals and notify me when you find one."';
const SEARCH_OPTION_ORDER: SearchOptionTypeEnum[] = Array.from(Object.values(SearchOptionTypeEnum));
function SearchInput(): React.ReactElement {
    const navigate = useNavigate();
    const [, setValue] = React.useState<ISearchOptionData | null>(null);
    const [, setInputValue] = React.useState("");
    const [options, setOptions] = useState<ISearchOptionData[]>([]);
    const [loading, setLoading] = useState(false);

    const debouncedInputChange = _.debounce(async (newVal: string) => {
        try {
            setInputValue(newVal);
            setLoading(true);

            if (!StringUtils.IsStringUndefinedNullOrEmpty(newVal)) {
                setLoading(newVal !== "");
                await fetchSearchDataAsync(newVal);
            }
        } finally {
            setLoading(false);
        }
    }, 1000);

    const fetchSolutionAsync = async (newVal: string): Promise<ISearchOptionData[]> => {
        let formattedDataArray: ISearchOptionData[] = [];

        const semanticConf = "solution-semantic-conf";
        const solutionSemanticSearchUrl = `${REACT_APP_BACKEND_SOLUTION_INDEX_URL}&search=${newVal}&$top=${MAX_RESULTS}&queryLanguage=en-US&speller=lexicon&queryType=semantic&semanticConfiguration=${semanticConf}`;
        try {
            const response = await axios.get(solutionSemanticSearchUrl, {
                headers: {
                    "Content-Type": "application/json",
                    "api-key": REACT_APP_API_KEY,
                },
            });
            const dataArray = response.data.value;
            formattedDataArray = dataArray.map(
                (item: any) =>
                    ({
                        label: item.solutionName ?? item.solution_name,
                        option_type: SearchOptionTypeEnum.RelatedSolution,
                        data: {
                            solutionId: item.solutionId ?? item.solution_id,
                            solutionName: item.solutionName ?? item.solution_name,
                            solutionDescription: item.solutionDescription ?? item.solution_description,
                            solutionSystemPrompt: item.solutionSystemPrompt ?? item.solution_system_prompt,
                            entityType: LoadEntityTypeEnum.Solution,
                            search_term: newVal,
                        } as ISolutionSearchResult,
                        filterName: "",
                        filterValues: [],
                        route: "/chat",
                    } as ISearchOptionData)
            );
        } catch (error) {
            console.log(error);
        }
        return formattedDataArray;
    };

    const fetchAssistOptionAsync = async (newVal: string): Promise<ISearchOptionData[]> => {
        // Let MuggleChat help user generate agent/prompt
        const formattedDataArray: ISearchOptionData[] = [
            {
                label: `Let Muggle AI help you with "${newVal}" ...`,
                option_type: SearchOptionTypeEnum.MuggleChat,
                data: {
                    search_term: newVal,
                    entityType: LoadEntityTypeEnum.Agent,
                } as IPromptSearchResult,
                filterName: "",
                filterValues: [],
                route: "/chat",
            } as ISearchOptionData,
        ];
        return formattedDataArray;
    };

    const fetchPromptAsync = async (newVal: string): Promise<ISearchOptionData[]> => {
        let formattedDataArray: ISearchOptionData[] = [];
        const semanticConf = "prompt-semantic-conf";
        const promptSemanticSearchUrl = `${REACT_APP_BACKEND_PROMPT_INDEX_URL}&search=${newVal}&$top=${MAX_RESULTS}&queryLanguage=en-US&speller=lexicon&queryType=semantic&semanticConfiguration=${semanticConf}`;
        try {
            const response = await axios.get(promptSemanticSearchUrl, {
                headers: {
                    "Content-Type": "application/json",
                    "api-key": REACT_APP_API_KEY,
                },
            });

            const dataArray = response.data.value;
            formattedDataArray.push(
                ...dataArray.map(
                    (item: any) =>
                        ({
                            label: StringUtils.CapStringSize(item.prompt, MAX_SEARCH_DISPLAY_CHARACTER),
                            option_type: SearchOptionTypeEnum.RelatedPrompt,
                            data: {
                                ...item,
                                search_term: newVal,
                                entityType: LoadEntityTypeEnum.Prompt,
                            },
                            filterName: "",
                            filterValues: [],
                            route: "/workbench", // Add your actual route here
                        } as ISearchOptionData)
                )
            );

            for (const ent of Object.entries(staticOptions)) {
                formattedDataArray.push({
                    label: `Find prompt/agent by ${ent[1]}`,
                    option_type: SearchOptionTypeEnum.FindBy,
                    data: undefined,
                    filterName: ent[0],
                    filterValues: Array.from(new Set(dataArray.map((d: any) => d[ent[0]]))),
                    route: "/discovery",
                } as ISearchOptionData);
            }
        } catch (error) {
            console.log(error);
        }
        return formattedDataArray;
    };

    const fetchSearchDataAsync = async (newVal: string): Promise<void> => {
        try {
            const retArry = await Promise.all([
                fetchAssistOptionAsync(newVal),
                fetchPromptAsync(newVal),
                fetchSolutionAsync(newVal),
            ]);
            let orderedRet: ISearchOptionData[] = retArry.flat();

            setOptions(orderedRet);
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    };

    const fetchSentenceDataAsync = async (promptId: string): Promise<ISentence[]> => {
        if (StringUtils.IsStringUndefinedNullOrEmpty(promptId)) return [];
        const sentenceSearchUrl = `${REACT_APP_BACKEND_SENTENCE_INDEX_URL}&search=*&$filter=prompt_id eq '${promptId}'`;
        const response = await axios.get(sentenceSearchUrl, {
            headers: {
                "Content-Type": "application/json",
                "api-key": REACT_APP_API_KEY,
            },
        });
        const sentences = response.data.value.map(
            (item: any) =>
                ({
                    ...item,
                } as ISentence)
        );
        return sentences;
    };

    const handleSelectOptionAsync = async (
        event: React.ChangeEvent<unknown>,
        value: ISearchOptionData | null
    ): Promise<void> => {
        if (value === null) return;
        if (StringUtils.IsStringUndefinedNullOrEmpty(value.route)) return;

        setValue(value);

        // Fetch sentence data for prompt
        if (
            value.data !== undefined &&
            value.option_type === SearchOptionTypeEnum.RelatedPrompt &&
            !StringUtils.IsStringUndefinedNullOrEmpty(value.data.promptId)
        ) {
            const sentences = await fetchSentenceDataAsync(value.data.promptId as string);
            value.data.sentences = sentences;
        }

        navigate(value.route, {
            state: {
                searchResult: value.data,
                option_type: value.option_type,
                filterName: value.filterName,
                filterValues: value.filterValues,
            },
        });
    };

    const sortedOptions = options.sort((a, b) => {
        if (a == null) return -1;
        if (b == null) return 1;
        return SEARCH_OPTION_ORDER.indexOf(a.option_type) - SEARCH_OPTION_ORDER.indexOf(b.option_type);
    });

    return (
        <Autocomplete
            className={styles.inputBox}
            id="search-input"
            onInputChange={(event, newInputValue) => {
                debouncedInputChange(newInputValue);
            }}
            filterOptions={(x) => x}
            onChange={(e, v) => handleSelectOptionAsync(e, v)}
            options={sortedOptions}
            groupBy={(option) => option.option_type}
            openOnFocus={true}
            clearOnBlur={false}
            renderInput={(params) => (
                <TextField
                    {...params}
                    placeholder={SEARCH_BAR_PLACEHOLDER}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <React.Fragment>
                                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                            </React.Fragment>
                        ),
                    }}
                />
            )}
            renderOption={(props, option, { inputValue }) => {
                if (option.option_type === SearchOptionTypeEnum.FindBy) {
                    return (
                        <li {...props}>
                            <div>{option.label}</div>
                        </li>
                    );
                }
                if (option.option_type === SearchOptionTypeEnum.MuggleChat) {
                    return (
                        <li {...props}>
                            <div>{option.label}</div>
                        </li>
                    );
                }

                if (option.data === undefined) {
                    return <li {...props}></li>;
                }

                const matches = match(option.label, inputValue, {
                    insideWords: true,
                });
                const parts = parse(option.label, matches);
                return (
                    <li {...props}>
                        <div>
                            {parts.map((part, index) => (
                                <span
                                    key={index}
                                    style={{
                                        fontWeight: part.highlight ? 700 : 400,
                                    }}
                                >
                                    {part.text}
                                </span>
                            ))}
                        </div>
                    </li>
                );
            }}
        />
    );
}

export default SearchInput;
